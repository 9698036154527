import { Component, ElementRef, ViewChild, Input, Output, HostListener, HostBinding, NgZone, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription, Observable, BehaviorSubject, Subject } from 'rxjs';
import { Playback, PlaylistType } from '../playback';
import { BaseComponent, SoftwareKeyboard, Shell, SubscriptionSet, sleep, LoggerService, PeriodicTaskService, Icon } from '@tytapp/common';
import { LocalStorageService } from '@tytapp/common';
import { NavigationEnd, NavigationStart } from '@angular/router';
import { UserService } from '@tytapp/user';
import {
    ProductionsApi, PlaybackApi, ApiPlaybackSession, ApiLiveStream, ApiVOD, ApiUser, ApiPodcast, ApiAvAsset,
    ApiProduction
} from '@tytapp/api';
import { filter } from 'rxjs/operators';
import { environment } from '@tytapp/environment';
import { isClientSide, isServerSide } from '@tytapp/environment-utils';
import { BillingService } from '@tytapp/billing';
import { ProductionsService } from '@tytapp/common-ui';
import { HostApi } from '@tytapp/common';
import { MatTabGroup } from '@angular/material/tabs';
import { MediaServiceRegistry, PlaybackSession } from '../media-services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AnalyticsService } from '@tytapp/analytics';

const MEMBERSHIP_C2A: CallToAction = {
    title: 'Enjoying this free video?',
    message: ' This video and thousands more are available for free thanks to the support of our members.',
    briefMessage: 'Our members make it possible.',
    icon: { type: 'plus' },
    type: 'url',
    style: 'membership',
    url: '/join/membership',
    duration: 8_000,
};

export interface CallToAction {
    type: 'poll' | 'url';
    message: string;

    /**
     * A briefer version of the message, used when space is tight.
     * When omitted, the normal message is used.
     */
    briefMessage?: string;

    style?: 'membership';
    icon?: Icon;

    // URL options
    url?: string;
    title?: string;
    duration?: number;
}

export interface PlayerMenuItem {
    icon: string;
    label: string;
    handler: () => void;
}

@Component({
    selector: 'tyt-media-player',
    templateUrl: './player.component.html',
    styleUrls: ['./player.component.scss']
})
export class PlayerComponent extends BaseComponent {
    private domSanitizer = inject(DomSanitizer);
    private elementRef: ElementRef<HTMLElement> = inject(ElementRef);
    private playback = inject(Playback);
    private localStorage = inject(LocalStorageService);
    private softwareKeyboard = inject(SoftwareKeyboard);
    private userService = inject(UserService);
    private playbackApi = inject(PlaybackApi);
    private billing = inject(BillingService);
    private productionsService = inject(ProductionsService);
    private hostApi = inject(HostApi);
    private mediaServiceRegistry = inject(MediaServiceRegistry);
    private snackBar = inject(MatSnackBar);
    private periodicTasks = inject(PeriodicTaskService);
    private analytics = inject(AnalyticsService);

    constructor() {
        super();

        // Stop playing media when the user logs out

        this.subscribe(this.billing.entitlementChanged, entitled => this.entitled = entitled);
        this.subscribe(this.userService.userChanged, user => {

            this.user = user;

            if (this.loggedIn && !user && this.asset) {
                this.dismiss();
            }

            this.loggedIn = user ? true : false;
        });

        if (isClientSide() && typeof IntersectionObserver !== 'undefined') {
            this.zone.runOutsideAngular(() => {
                this.intersectionObserver = new IntersectionObserver((entries) => {
                    // conditional inline/pip is only needed for inline video media players
                    if (!this.inline || this.assetType == 'podcast')
                        return;

                    let entry = entries[entries.length - 1];
                    let intersecting = entry && (entry.intersectionRatio > 0 || entry.isIntersecting);
                    let showPip = !intersecting;

                    if (window.scrollY < 300)
                        showPip = false;

                    if (showPip != this.pip) {
                        console.log(`PIP state change to ${!intersecting}`);
                        this.zone.runGuarded(() => this.pip = !intersecting);
                    }
                }, {
                    rootMargin: '0px',
                    threshold: 0
                });
            });

            this.intersectionObserver.observe(this.elementRef.nativeElement);
        }

    }

    get element() {
        return this.elementRef.nativeElement;
    }

    @Input()
    allowKeyboardControls = true;

    @Input()
    additionalMenuItems: PlayerMenuItem[] = [];

    get livePosition() {
        return Math.max(0, this.length - this.rawPosition);
    }

    isRefreshingResolution = false;
    refreshAutoplay: boolean = false;

    async refreshMediaResolution(autoplay: boolean) {
        this.logger.info(`Refreshing media resolution (autoplay=${autoplay}, position=${this.rawPosition})...`);
        this.isRefreshingResolution = true;
        this.refreshAutoplay = autoplay;
        return await this.playContent(this.production, this.playlistID, this.media, this.rawPosition);
    }

    get currentSessionChanged(): Observable<PlaybackSession> {
        return this.newSession;
    }

    getCurrentPlaybackSession() {
        return this._session;
    }

    intersectionObserver: IntersectionObserver;
    private loggedIn: boolean = false;
    user: ApiUser = null;
    entitled: boolean = false;

    isEntitled(media: ApiVOD | ApiPodcast | ApiLiveStream) {
        if ('entitled' in media)
            return media.entitled;

        if ('active_live_event' in media) {
            return media.asset && media.active_live_event.entitled;
        }

        return this.entitled;
    }

    @HostBinding('class.has-paywall')
    showPaywall: boolean = false;

    get cancelDate() {
        if (!this.user || !this.user.membership)
            return;
        return this.user.membership.cancelled_at;
    }

    isPremium = false;
    bannerTopicCustomText;

    @Input() inEditor = false;

    get topicIdentifier() {
        if (!this.media) {
            return null;
        }

        if (['clips', 'episodes'].includes(this.playlistID))
            return `vod_${this.media.id}`;
        else if (this.playlistID === 'podcasts')
            return `podcast_${this.media.id}`;
        else if (this.assetType === 'live_stream') {
            let stream = <ApiLiveStream>this.media;
            let date = new Date(stream.started);
            let dateStr = date.toISOString().substring(0, 10);
            return `tytlive_${dateStr}`;
        }

        return `media_${this.media.id}`;

    }
    get videoDisabled() {
        let session = this.getCurrentPlaybackSession();

        if (session && session.videoDisabled)
            return true;

        return false;
    }

    apiSession: ApiPlaybackSession;
    sessionSyncCounter: number = 0;

    @HostBinding('attr.data-media-provider')
    mediaProviderName: string = null;

    async startPlaybackTracking(production: ApiProduction, playlist_item: ApiPodcast | ApiVOD, playlist_id: string) {
        this.analytics.sendBeacons(`Loaded media for playback (${playlist_id})`, () => {
            this.analytics.sendBeaconGA4('tytcom_media_loaded', {
                livestream: false,
                playlist_id: playlist_id.replace(/s$/, ''),
                media: `${production.id}/${playlist_id}/${playlist_item.id}`,
                production: production.id,
                playlist: playlist_id,
                playlistItem: playlist_item.id
            });
        });

        this.mediaProviderName = undefined;
        if (playlist_item.asset)
            this.mediaProviderName = playlist_item.asset.provider;

        this.apiSession = null;
        try {
            this.apiSession = <any>await this.playbackApi.startSession({
                production_id: production.id,
                playlist_item_id: playlist_item.id,
                playlist_id: playlist_id,
                app_name: 'tytapp'
            }).toPromise();

            if (this.apiSession) {
                this.sessionSyncCounter = this.apiSession.sync_counter;
            } else {
                this.sessionSyncCounter = 0;
            }

        } catch (e) {
            if (e.error !== 'access-denied') {
                this.logger.error(`Caught error while starting playback session:`);
                this.logger.error(e);
            }
            this.apiSession = null;
        }
    }

    private get resumePosition() {
        if (this.entitled && this.apiSession)
            return this.apiSession.position;

        return 0;
    }

    private paywallProduction: ApiProduction = null;
    private paywallContentType: string = null;
    private paywallContentIndex: number = null;

    @Input() get stream() {
        if (this.media.type == 'live_stream')
            return this.media as ApiLiveStream;
    }

    @Input() startMuted = false;

    set stream(value) {
        if (!value)
            return;

        if (this.media !== value) {
            setTimeout(() => {
                this.playStream(value);
            })
        }
    }

    async playStream(stream: ApiLiveStream): Promise<PlaybackSession> {
        this.analytics.sendBeacons(`Loaded live stream for playback (Stream #${stream.id})`, () => {
            this.analytics.sendBeaconGA4('tytcom_media_loaded', {
                livestream: true,
                stream: stream.id,
                assetProvider: stream.asset.provider,
                assetUrl: stream.asset.url,
                assetID: stream.asset.identifier,
            });
        });

        return await this.playContent(null, null, stream, 0);
    }

    errorTitle = 'Uh oh...';
    errorMessage: string;

    private getPlaylist(production: ApiProduction, playlist: PlaylistType): ApiVOD[] | ApiPodcast[] {
        if (!production)
            return undefined;

        if (playlist === 'episodes')
            return this.production.full_length_vods;
        else if (playlist === 'clips')
            return this.production.vod_clips;
        else if (playlist === 'podcasts')
            return this.production.full_length_podcasts;
        else if (playlist === 'audio-stories')
            return this.production.audio_clips;
        else
            throw new Error(`Invalid playlist type '${playlist}'`);
    }

    async playItem(vod: ApiVOD | ApiPodcast) {
        await this.playContent(this.production, this.playlistID, vod);
    }

    async releaseSession() {
        await this.disconnectSession(false);
    }

    async receiveSession(
        session: PlaybackSession,
        production: ApiProduction,
        playlistID: PlaylistType,
        media: ApiPodcast | ApiVOD | ApiLiveStream
    ) {
        this.production = production;
        this.playlistID = playlistID;
        this.playlist = this.getPlaylist(this.production, this.playlistID);
        this.media = media;
        this.playing = true;
        this.pip = !this.inline;
        this.hasPlayed = true;

        await session.moveInto(this.playbackElement);
        this.connectSession(session, !this.playing);
        this.playback.registerSession(session,  {
            title: media.title ?? media.description,
            artist: production.show?.name,
            artwork: production.show ? [
                {
                    src: production.show.logo_square
                }
            ] : [],
            mediumType: media.type === 'live_stream' ? 'live' : 'recorded'
        });
    }

    async playContent(
        production: ApiProduction,
        playlistID: PlaylistType,
        media: ApiPodcast | ApiVOD | ApiLiveStream,
        position: number = undefined
    ): Promise<PlaybackSession> {
        if (this.destroyed || isServerSide()) {
            if (this.destroyed)
                this.logger.warning(`PlayerComponent#playContent(): Refusing to play media on a destroyed PlayerComponent`);
            return null;
        }

        // Before we attempt to set up a _new_ session, lets see if the app player is already
        // playing this content, and if so, take it over.
        // We do this using the playback.lock() mechanism in order to ensure multiple video embeds cannot race to
        // grab the session (though this is a corner case that would only happen if there were multiple embeds for
        // the same media on the same page).
        // When embedded in CMS' Content View, there are two copies of this player loaded (one for desktop and
        // one for mobile), and we need to not take over playback if we are the one in the background.

        if (!this.embed || (this.element as any).checkVisibility?.()) {
            await this.playback.lock(async () => {
                if (this.playback.isAppPlayerActive && this.playback.session && this.playback.currentPlayer && this.playback.currentPlayer !== this) {

                    let session = this.playback.session;
                    let otherItem = session.item;
                    let otherMedia = otherItem.item;
                    let otherProduction = otherItem.production;

                    if (otherMedia.type === media.type && otherMedia.id === media.id && session.moveInto) {
                        await this.playback.currentPlayer.releaseSession();
                        await this.receiveSession(session, production, playlistID, media);
                        this.playback.takePlayback(this);
                        return;
                    }
                }
            });
        }

        this.isPremium = (<ApiVOD | ApiPodcast>media).is_premium ?? (<ApiLiveStream>media).premium ?? false;

        if (media.type === 'vod') {
            const vod = <ApiVOD>media;
            if (vod.topics[0]?.topic_type === 'topic') {
                this.bannerTopicCustomText = vod.topics[0].name;
            }
        }

        if (!this.inline) {
            this.logger.info(`[PlayerComponent] Not playing inline, entering PIP mode.`);
            this.pip = true;
        }

        if (media.type !== 'live_stream')
            await this.startPlaybackTracking(production, <ApiPodcast | ApiVOD> media, playlistID);

        position ??= this.resumePosition;

        this.mediaProviderName = media.asset ? media.asset.provider : undefined;
        this.assetType = <'podcast' | 'vod' | 'live_stream'>media.type;
        this.production = production;
        this.showPaywall = false;
        this._paywallVisible.next(false);
        this._currentItemChanged.next(media);

        let vodsDisabled = await this.shell.hasFeature('apps.web.vods_disabled');
        let podcastsDisabled = await this.shell.hasFeature('apps.web.podcasts_disabled');

        if (['vod'].includes(media.type) && vodsDisabled) {
            media.asset = null;
            this.errorTitle = `Unavailable`;
            this.errorMessage = `On-demand video playback is not currently available. Please try again later.`;
            return null;
        } else if (['podcast'].includes(media.type) && podcastsDisabled) {
            media.asset = null;
            this.errorTitle = `Unavailable`;
            this.errorMessage = `Podcast playback is not currently available. Please try again later.`;
            return null;
        } else if (!media.asset) {
            if (this.inEditor) {
                this.errorTitle = `Missing Asset`;
                this.errorMessage = `This VOD has no video asset associated.`;
                return;
            }

            if (this.isEntitled(media)) {
                this.logger.error(`Missing asset on VOD while trying to start playback, but user is entitled!`);
                this.errorMessage = `Looks like we\'re having trouble playing back this content at the moment. Please try again later.`;
                return;
            } else {
                this.disconnectSession();

                // Since we won't be calling connectSession(), which issues playback.takePlayback(), but we still want
                // to stop the app-wide player (or any other player) from playing while we present the paywall, we'll
                // revoke the playback of whatever other player might be out there.

                if (this.playback.currentPlayer != this)
                    this.playback.revokePlayback();

                await this.doShowPaywall();

                this.paywallProduction = production;
                this.paywallContentType = 'live';
                this.paywallContentIndex = 0;

                if (this.paywallProduction)
                    this.localStorage.set('productionId', this.paywallProduction.id);
                else
                    this.localStorage.set('productionId', '');
                this.localStorage.set('productionContentType', this.paywallContentType);
                this.localStorage.set('productionContentIndex', this.paywallContentIndex);

                if (production) {
                    if (production.full_length_vods.find(x => x.id == media.id)) {
                        this.paywallContentType = 'episode';
                        this.paywallContentIndex = production.full_length_vods.findIndex(x => x.id == media.id);
                    } else if (production.full_length_podcasts.find(x => x.id == media.id)) {
                        this.paywallContentType = 'podcast';
                        this.paywallContentIndex = production.full_length_podcasts.findIndex(x => x.id == media.id);
                    } else if (production.vod_clips.find(x => x.id == media.id)) {
                        this.paywallContentType = 'clip';
                        this.paywallContentIndex = production.vod_clips.findIndex(x => x.id == media.id);
                    }
                }

                if (!this.inline) {
                    if (this.assetType == 'vod') {
                        this.dismiss();
                        this.router.navigateByUrl(await this.productionsService.getWatchUrl(production, this.playlistID, media));
                    }
                }

                this._paywallVisible.next(true);
                this.analytics.sendBeacons(`Showed Media Paywall`, () => {
                    this.analytics.sendBeaconGA4('tytcom_media_paywall', {
                        assetProvider: this.media.asset.provider,
                        assetID: this.media.asset.identifier,
                        assetUrl: this.media.asset.url,
                        mediaType: this.media.type,
                        mediaId: this.media.id,
                        playlistType: this.playlistID
                    });
                });

                return null;
            }
        }

        if (!media.asset)
            throw new Error('playContent(): Asset parameter cannot be null');

        let resolution = this.mediaServiceRegistry.resolve({
            production,
            item: media
        });

        if (!resolution) {
            alert(`Media with asset URL ${media.asset?.url ?? '<none>'} is not supported!`);
            return null;
        }

        let wasPlayingHere = ['playing', 'buffering'].includes(this._session?.playState);
        let wasPlayingThere = ['playing', 'buffering'].includes(this.playback.session?.playState);

        let session: PlaybackSession;
        let autoplay = this.refreshAutoplay || this.autoplay || wasPlayingHere || wasPlayingThere;

        try {
            session = await resolution.service.play(resolution, this.playbackElementRef, {
                isLive: this.isLive,
                autoplay,
                startPosition: position ?? 0
            });
        } catch (e) {
            this.logger.error(`Caught error while attempting to play media:`);
            this.logger.error(e);
            this.logger.info(`Media involved with error:`);
            this.logger.inspect(resolution);

            //alert(e.message);
            return null;
        }

        // If the session does not automatically handle seeking on start, handle that manually here.

        let enableSeekOnStart = session.enableSeekOnStart ?? true;
        if (position && enableSeekOnStart) {
            session.seek(position);
        }

        // Make sure the video player is on screen

        if (this.playbackElementRef) {
            this.playbackElementRef.nativeElement.scrollIntoView({
                block: 'center',
                behavior: 'smooth'
            });
        }

        this.playlistID = playlistID;
        this.playlist = production ? this.getPlaylist(production, playlistID) : [ media ];
        this.media = media;
        this.asset = media.asset;

        if (this.isRefreshingResolution) {
            autoplay = this.refreshAutoplay;
            this.logger.info(`PlayerComponent: Refreshing resolution, autoplay=${autoplay}`);
        } else if (wasPlayingHere) {
            this.logger.info(`PlayerComponent: Forcing autoplay as we move onto the next playlist item...`);
            autoplay = true;
        }

        if (autoplay) {
            this.analytics.sendBeacons(`Autoplaying loaded media`, () => {
                this.analytics.sendBeaconGA4('tytcom_playback_started', {
                    autoplay: true,
                    assetProvider: this.media.asset.provider,
                    assetID: this.media.asset.identifier,
                    assetUrl: this.media.asset.url,
                    mediaType: this.media.type,
                    mediaId: this.media.id,
                    via: 'autoplay'
                });
            });

            this.playing = true;
        } else {
            this.playing = false;
        }

        let show = production?.show ?? (media as ApiLiveStream).active_live_event?.show;
        this.connectSession(session, !wasPlayingHere);
        this.playback.registerSession(this._session, {
            title: media.title ?? media.description,
            artist: show?.name,
            artwork: show ? [
                {
                    src: show?.logo_square
                }
            ] : [],
            mediumType: media.type === 'live_stream' ? 'live' : 'recorded'
        });
        return this._session;
    }

    private async doShowPaywall() {
        this.showPaywall = true;

        // On native builds where we typically have a platform-specific
        // billing flow, we want to show the In App Purchase upsell overlay
        // right away, and let them dismiss it if they want to see the page with
        // the player.

        if (environment.isNativeBuild)
            this.billing.presentMembershipOffers();
    }

    @Input()
    enableControls: boolean = true;

    @Input()
    production: ApiProduction;

    @Input()
    autoplay: boolean = true;

    @Output()
    get currentItemChanged(): Observable<ApiPodcast | ApiVOD | ApiLiveStream> {
        return this._currentItemChanged;
    }

    @Output()
    get playbackRevoked(): Observable<void> {
        return this._playbackRevoked;
    }

    _currentItemChanged: BehaviorSubject<ApiPodcast | ApiVOD | ApiLiveStream> = new BehaviorSubject<ApiPodcast | ApiVOD | ApiLiveStream>(null);
    _playbackRevoked: Subject<void> = new Subject<void>();

    @ViewChild('playbackElement', { static: true })
    private playbackElementRef: ElementRef<HTMLElement>;
    private _session: PlaybackSession = null;

    get playbackElement() {
        return this.playbackElementRef?.nativeElement;
    }

    playlistID: PlaylistType;
    playlist: (ApiVOD | ApiPodcast | ApiLiveStream)[] = [];

    assetType: 'podcast' | 'vod' | 'live_stream' = null;
    private _activeSection = "playlist";

    get activeSection() {
        return this._activeSection;
    }

    set activeSection(value) {
        this._activeSection = value;
        if (value === 'comments') {
            this.loadComments = true;
        }
    }

    loadComments = false;

    initKeys() {

        this.subscribe(this.softwareKeyboard.keyboardChanged, visible => {
            this.softwareKeyboardVisible = visible;
        });

        if (typeof document !== 'undefined') {
            document.addEventListener('keydown', ev => {
                if (this.fullscreen) {
                    if (ev.key == 'Escape') {
                        this.exitFullscreen();
                    }
                }
            });
        }
    }

    softwareKeyboardVisible: boolean = false;

    public playableDescriptionHtml: any;

    get isRewinded() {
        return this.rawPosition + 30 < this.length;
    }

    jumpToLive() {
        this.seekTo(this.length + 9999);
    }

    @Input()
    isLive = false;

    get hasSession() {
        return this._session != null;
    }

    get videoLabel() {
        if (this.isLive)
            return 'live stream';

        if (!this.assetType)
            return 'content';

        if (this.assetType == 'podcast')
            return 'podcast';
        else
            return 'video';
    }

    get session() {
        return this._session;
    }

    get sessionSeekable() {
        if (!this._session)
            return false;

        let seekable = this._session.seekable;

        if (seekable === undefined)
            return true;

        return seekable && this._session.length > 0;
    }

    get length() {
        if (!this._session)
            return 0.01;

        return this._session.length;
    }

    get hasMoreQueueItems() {
        if (!this.media || this.media.type === 'live_stream' || !this.playlist)
            return false;

        return this.playlist.findIndex(x => x.id == this.media.id) < this.playlist.length;
    }

    /**
     * This method is a place for hacks meant to workaround compatibility with
     * popular browser extensions that interfere with the TYT media player.
     */
    installBrowserExtensionWorkarounds() {

        /**
         * Video Speed Controller extension for Google Chrome inserts an element within
         * #playback-element that causes a major layouting bug. This code detects the existence
         * of VSC controller and moves it back outside of playback-element so that it can still
         * be used by the user. There is companion CSS inside styles.scss that stop the vsc-controller
         * div from causing the layout bug. This code just makes the functionality accessible to
         * the user since content in #playback-element is not generally clickable on our site.
         */
        let vscTimer;
        let vscCheckCount = 0;

        if (isClientSide()) {
            vscTimer = this.periodicTasks.schedule(5000, () => {
                vscCheckCount += 1;
                if (vscCheckCount > 5)
                    this.periodicTasks.cancel(vscTimer);

                let element: HTMLElement = this.elementRef.nativeElement;
                let vscController = element.querySelector('#playback-element .vsc-controller');
                if (!vscController)
                    return;

                element.insertBefore(vscController, element.firstChild);
                this.periodicTasks.cancel(vscTimer);
            });
        }
    }

    audienceType: string = 'visitor';

    init() {
        this.registerKeyboardHandler();
        this.installBrowserExtensionWorkarounds();

        if (isClientSide()) {
            window.addEventListener('orientationchange', e => {
                if (!this.playing || typeof screen === 'undefined')
                    return;

                if (screen.orientation?.type.startsWith('landscape-')) {
                    this.goFullscreen(false, true);
                }
            });

            window['player'] = this;
        }

        this.subscribe(this.router.events.pipe(filter(x => x instanceof NavigationEnd)), ev => {
            this.exitFullscreen();
            this.navigating = false;
        });

        this.subscribe(this.router.events.pipe(filter(x => x instanceof NavigationStart)), ev => {
            this.navigating = true;
        });

        this.initKeys();

        this.subscribe(this.playback.playerChanged, player => {
            if (player !== this) {
                this.showPaywall = false;
                this._paywallVisible.next(false);
            }
        });
    }

    destroyed: boolean = false;
    navigating = false;

    destroy() {
        if (this.destroyed)
            return;

        this.destroyed = true;
        this.removeKeyboardHandler();

        // Send the session to the app player if we are navigating and currently playing media.
        // Otherwise, tell the playback service that we are no longer an active player.

        let willTransfer = !this.suppressAppTransfer && !this.disableAppTransfer && this.navigating && this.inline && this.media && this.playing;

        let sessionForTransfer: PlaybackSession;
        if (willTransfer && this.session?.moveInto) {
            sessionForTransfer = this._session;
        }

        this.disconnectSession(!willTransfer || !this.session?.moveInto);

        if (willTransfer) {
            try {
                this.playback.transferPlaybackToApp(
                    sessionForTransfer,
                    this.production,
                    this.playlistID,
                    this.media,
                    this.rawPosition
                );
            } catch (e) {
                this.logger.error(`Caught error while trying to transfer playback to app-player:`);
                this.logger.error(e);
            }
        } else {
            this.playback.releasePlayback(this);
        }

        if (this.intersectionObserver) {
            try {
                this.intersectionObserver.unobserve(this.elementRef.nativeElement);
            } catch (e) {
                this.logger.error(`Caught error while trying to unobserve a player element's intersection:`);
                this.logger.error(e);
            }
        }

        this.sessionSubscriptions?.unsubscribeAll();
    }

    private _positionSub = null;

    /**
     * Set to true to transiently suppress PiP, regardless of user state and configuration.
     *
     */
    pipSuppressed = false;

    hideOrDismiss() {
        if (this.inline) {
            this.logger.info(`[PlayerComponent] Hide or dismiss action, exiting PIP.`);
            this.pip = false;
            this.exitFullscreen();
        } else {
            this.dismiss();
        }
    }

    dismiss() {
        if (this.inline) {
            if (this._session) {

                try {
                    if (this._session.pauseOnDismiss !== false)
                        this._session.pause();
                } catch (e) {
                    this.logger.error(`Caught error while pausing session during dismiss:`);
                    this.logger.error(e);
                }

                try {
                    this.playback.unregisterSession(this._session);
                    this._session.destroy();
                } catch (e) {
                    this.logger.error(`Caught error while destroying session during dismiss:`);
                }

                this._session = null;
            }

            this.pipSuppressed = true;
            this.exitFullscreen();
            return;
        }

        this.showPaywall = false;
        this._paywallVisible.next(false);

        if (this._session) {
            this.playback.unregisterSession(this._session);
            this._session.destroy();
        }

        this.media = null;
        this.asset = null;
        this._currentItemChanged.next(null);
    }

    stopPlaying() {
        this.exitFullscreen();
        this.fullscreen = false;

        setTimeout(() => {
            if (this._session) {
                this._session.destroy();
            }
            this.asset = null;
            this.media = null;
        });
    }

    get trueFullscreen() {
        if (typeof document === 'undefined')
            return false;

        return document['webkitIsFullScreen']
            || document['fullscreen']
            || document['mozFullScreen']
            || document['webkitIsFullScreen']
            || document['webkitIsFullScreen']
            ;
    }

    skip() {
        this.nextItem();
    }

    notifying: boolean = false;
    notifyMessage: string = null;

    notify(message: string) {
        this.notifying = true;
        this.notifyMessage = message;
        setTimeout(() => {
            this.notifying = false;
        }, 4000);
    }

    callingToAction: boolean = false;
    c2a: CallToAction;

    showPoll() {
        this.showSidebar();
        this.activeSection = 'playlist';
    }

    async testCallToAction() {
        await sleep(1000);
        this.callToAction({
            message: 'This is the message',
            title: 'This is the title',
            type: 'url',
            url: 'https://google.com'
        });
    }

    private callToActionDismissTimer;
    /**
     * Call To Action!
     */
    async callToAction(c2a: CallToAction) {
        this.c2a = c2a;
        this.callingToAction = true;

        clearTimeout(this.callToActionDismissTimer);
        this.callToActionDismissTimer = setTimeout(() => {
            this.dismissCallToAction();
        }, c2a.duration ?? 15_000);
    }

    dismissCallToAction() {
        console.log('DISMISS C2A');
        this.callingToAction = false;
        clearTimeout(this.callToActionDismissTimer);
    }

    /**
     * True if this player is placed within a page, as opposed to as a shell UI element, as the app-wide player is.
     */
    @Input()
    inline: boolean = false;

    nextItem() {
        this.playNextItem();
        this.analytics.sendBeacons(`Skipped to next playlist item`, () => {
            this.analytics.sendBeaconGA4('tytcom_playback_skipped', {
                assetProvider: this.media.asset.provider,
                assetID: this.media.asset.identifier,
                assetUrl: this.media.asset.url,
                mediaType: this.media.type,
                mediaId: this.media.id
            });
        });
    }

    previousItem() {
        this.playPreviousItem();
    }

    /**
     * True if this media player is being presented within the current page (as opposed to being shown in PiP or
     * fullscreen status)
     */
    get isInline() {
        return this.inline && !this.fullscreen && !this.pip;
    }

    get isPaywallAndInline() {
        return this.showPaywall && this.inline;
    }

    /**
     * Set to true to disable the ability for the player to enter in-page PiP mode.
     */
    @Input() disablePip = false;

    /**
     * True when the player is being presented in in-page PiP.
     * Many states disable this feature.
     */
    @HostBinding('class.is-pip')
    get isPip() {
        return this.assetType != 'podcast'
            && !this.isPaywallAndInline
            && !this.session?.pictureInPicture
            && !this.showPaywall
            && !this.errorMessage
            && this.pip
            && !this.fullscreen
            && !this.pipSuppressed
            && !this.disablePip
        ;
    }

    revokePlayback() {

        if (this.inline) {
            this.session?.pause();
        } else {
            // When we are the app-wide player (inline=false), its important to drop what we have loaded.
            this.stopPlaying();
            this.media = null;
            this._currentItemChanged.next(null);
        }

        this.showPaywall = false;
        this._playbackRevoked.next();
    }

    private historyUpdater = null;
    private watchingSince: Date = null;
    private lastPingedAt = Date.now();
    private pingInterval = 10 * 1000;

    /**
     * Playback position in seconds. For percentage, see seekPosition
     */
    rawPosition: number = 0;
    private accruedWatchTime: number = 0;

    private _playlistEnded: Subject<void> = new Subject<void>();

    @Output()
    public get playlistEnded(): Observable<void> {
        return this._playlistEnded;
    }

    async playNextItem(): Promise<PlaybackSession> {
        this.watchingSince = null;
        this.totalWatchTime = 0;
        this.accruedWatchTime = 0;

        let index = this.playlist.findIndex(x => x.id == this.media.id);
        let nextIndex = index + 1;

        if (nextIndex >= this.playlist.length) {
            if (this._session) {
                await this._session.pause();
                await this._session.seek(0);
                this.watchingSince = null;
                this.totalWatchTime = 0;
                this.accruedWatchTime = 0;
            }

            this._playlistEnded.next();
            return null;
        }

        return await this.playContent(this.production, this.playlistID, this.playlist[nextIndex]);
    }

    async playPreviousItem(): Promise<PlaybackSession> {
        let index = this.playlist.findIndex(x => x.id == this.media.id);
        let nextIndex = index - 1;

        if (nextIndex < 0) {
            if (this._session) {
                this._session.seek(0);
            }
            return null;
        }

        return await this.playContent(this.production, this.playlistID, this.playlist[nextIndex]);
    }

    @Input() disableAppTransfer = false;

    private suppressAppTransfer = false;

    @HostListener('window:popstate', ['$event'])
    onPopState(ev: PopStateEvent) {
        // This used to stop transfer of media from inline to app player when using back/forward buttons
        // but with the new seamless playback, it probably should be enabled. To make it so back/forward
        // do not launch the miniplayer, comment this back in.

        // if (this.inline) {
        //     this.suppressAppTransfer = true;
        // }

        if (ev.state && ev.state.type == 'player') {
            if (ev.state.player == 'full') {
                this.goFullscreen(false, true);
            }
        } else {
            this.exitFullscreen();
        }
    }

    private sessionSubscriptions: SubscriptionSet = new SubscriptionSet();
    private delaySessionPingsUntil: number = 0;
    private sessionPingCompletionTime: number = 0;

    async sendSessionPing() {

        if (!this.playback.enableSessionTracking)
            return;

        if (this.delaySessionPingsUntil > Date.now())
            return;

        let probation = false;
        if (this.delaySessionPingsUntil > 0) {
            probation = true;
            this.delaySessionPingsUntil = 0;
        }

        this.accrueWatchTime(true);

        let watchTime = this.accruedWatchTime;

        this.accruedWatchTime = 0;

        if (this.apiSession) {
            let requestStartedAt = Date.now();

            try {
                await this.playbackApi.updateSession(this.apiSession.id, {
                    time_watched: watchTime,
                    position: this.rawPosition,
                    sync_counter: ++this.sessionSyncCounter
                }).toPromise();
            } catch (e) {
                this.logger.error(`Caught an error while sending playback session ping:`);
                this.logger.error(e);

                if (probation) {
                    this.logger.error(`This is the second consecutive playback tracking failure. Disabling playback tracking for this media.`);
                    this.apiSession = null;
                } else {
                    let delay = 60 * 1000 + Math.floor(Math.random() * 30) * 1000;
                    this.delaySessionPingsUntil = Date.now() + delay;
                    this.logger.error(`Disabling playback session for ${delay}ms`);
                }

                return;
            }

            this.sessionPingCompletionTime = Date.now() - requestStartedAt;
        }
    }

    speed: number = 1;

    wasFullScreenBeforePiP = false;
    pipWindowMode = false;
    setPipWindowMode(enabled: boolean) {
        if (this.pipWindowMode === enabled)
            return;

        this.pipWindowMode = enabled;

        if (enabled) {
            this.wasFullScreenBeforePiP = this.fullscreen;
            if (!this.fullscreen)
                this.goFullscreen();
        } else {
            if (!this.wasFullScreenBeforePiP)
                this.exitFullscreen();
        }
    }

    showedMembershipC2A = false;

    /**
     * Pass true to mark this player as an embed. This changes a few behaviors to make the experience more intuitive,
     * for instance:
     * - Embed players do not grab playback control until they are played, versus normal players which grab playback
     *   control when they are loaded.
     */
    @Input() embed = false;

    connectSession(session: PlaybackSession, isFirst: boolean) {
        if (!this.embed)
            this.playback.takePlayback(this);

        this.disconnectSession();

        this._session = session;
        this.newSession.next(this._session);

        this.sessionSubscriptions.subscribe(session.positionChanged, pos => {
            this.seekPosition = pos / session.length;
            this.rawPosition = pos;

            if (this.inline && !this.entitled && !this.pipWindowMode && this.rawPosition > 10) {
                if (!this.showedMembershipC2A) {
                    this.showedMembershipC2A = true;
                    if (this.hostApi.capabilities.includes('web_billing:membership') || this.hostApi.capabilities.includes('platform_billing:membership')) {
                        let contextMessage = this.bannerTopicCustomText
                            ? `Support our coverage of **${this.bannerTopicCustomText}** now.`
                            : `**Become a member now.**`
                        ;

                        this.callToAction({
                            ...MEMBERSHIP_C2A,
                            message: `${MEMBERSHIP_C2A.message} ${contextMessage}`,
                            briefMessage: `${MEMBERSHIP_C2A.briefMessage} ${contextMessage}`
                        });
                    }
                }
            }

            // If our session pings are taking a long time to complete, let's slow down how fast we send updates to the server.
            if (this.sessionPingCompletionTime > 3000) {
                this.pingInterval = 30 * 1000;
            }

            if (this.lastPingedAt + this.pingInterval < Date.now()) {
                this.lastPingedAt = Date.now();
                this.sendSessionPing();
            }
        });

        this.sessionSubscriptions.subscribe(session.onSeek, pos => {
            this.sendSessionPing();
            this.lastPingedAt = Date.now();
        });

        this.speed = 1;
        if (session.speedChanged)
            this.sessionSubscriptions.subscribe(session.speedChanged, speed => this.speed = speed);
        this.sessionSubscriptions.subscribe(session.finished, () => {
            this.sendSessionPing();
            this.lastPingedAt = Date.now();
        });

        this.sessionSubscriptions.subscribe(session.playStateChanged, playState => {
            if (playState == 'paused' && this.lastPingedAt < Date.now() - 5 * 1000) {
                this.sendSessionPing();
                this.lastPingedAt = Date.now();
            }
        });

        let hasTweakedVolume = false;
        this.sessionSubscriptions.subscribe(session.volumeChanged, level => {
            if (this.volume === level)
                return;

            this.volume = level;
            clearTimeout(this.delayedSavePreferredVolume);

            // The goal here is to avoid saving the muted state as the user's preferred volume just because the caller
            // had the startMuted parameter set.

            if (this.startMuted) {
                hasTweakedVolume = level !== 0;
            } else {
                hasTweakedVolume = true;
            }

            if (hasTweakedVolume) {
                this.delayedSavePreferredVolume = setTimeout(() => {
                    this.preferredVolume = level;
                }, 2000);
            }
        });

        // Set preferred volume
        // But if it's audio only content, don't start fully muted or the
        // user won't understand why it doesn't play

        let preferredVolume = this.preferredVolume;

        if (this.startMuted)
            preferredVolume = 0;

        this.setVolume(preferredVolume, true);
        this.analytics.sendBeacons(`Set player to preferred volume (${Math.round(preferredVolume * 100)}%)`, () => {
            this.analytics.sendBeaconGA4('tytcom_playback_volume_preferred', {
                assetProvider: this.media.asset.provider,
                assetID: this.media.asset.identifier,
                assetUrl: this.media.asset.url,
                mediaType: this.media.type,
                mediaId: this.media.id,
                playlistType: this.playlistID,
                volume: Math.round(preferredVolume * 100)
            });
        });

        // Install chat if available

        this.reloadChat();

        if (isFirst) {
            if (this.hasChat) {
                this.hasSidebar = true;
                this.activeSection = 'chat';
            } else {
                this.hasSidebar = false;
                this.activeSection = 'playlist';
            }
        }

        // Install end handler

        this.sessionSubscriptions.subscribe(session.finished, () => {
            // Let the player know it can now move on
            if (this.destroyed)
                return;

            this.playNextItem();
        });

        this.sessionSubscriptions.subscribe(session.playStateChanged, state => {
            this.buffering = false;
            this.playing = false;

            if (state == 'paused') {
                this.accrueWatchTime(false);
            } else if (state == 'playing') {
                this.pipSuppressed = false;
                if (!this.hasPlayed) {
                    if (!this.watchingSince)
                        this.watchingSince = new Date();
                }
                this.hasPlayed = true;
                this.playing = true;
                this.startIdleTimeout();
            } else if (state == 'buffering') {
                this.buffering = true;
                this.playing = true;

                this.accrueWatchTime(false);
            }
        });

        if (session.closedCaptionsEnabled) {
            this.sessionSubscriptions.subscribe(
                session.closedCaptionsEnabled, state => {
                    this.enabledClosedCaption = state;
                }
            );
        }
    }

    useBantaChat = false;

    async reloadChat() {
        this.useBantaChat = await this.shell.hasFeature('apps.web.use_banta_chat');
        let session = this._session;

        if (!session)
            return;

        if (session.hasChat) {
            if ((this.media as ApiLiveStream).premium || (this.media as ApiVOD).is_premium) {
                this.hasChat = true;
                let chatEmbed = session.chatEmbed;
                this.platformChatEmbed = this.domSanitizer.bypassSecurityTrustHtml(chatEmbed);
            } else {
                this.hasChat = false;
                this.platformChatEmbed = undefined;
            }
        } else {
            this.hasChat = false;
            this.platformChatEmbed = null;
        }

        this.newChatEmbed.next(this.platformChatEmbed);
    }

    nextPlaybackRate() {
        if (!this.session || !this.session.supportedPlaybackSpeeds)
            return;

        let current = this.speed;
        let next: number = undefined;

        for (let supportedRate of this.session.supportedPlaybackSpeeds) {
            if (supportedRate > current) {
                next = supportedRate;
                break;
            }
        }

        if (next === undefined)
            next = this.session.supportedPlaybackSpeeds[0];

        if (next === undefined)
            return;

        this.session.setSpeed(next);
    }

    setPlaybackRate(rate: number) {
        this.session.setSpeed(rate);
    }

    hasChat: boolean = false;
    platformChatEmbed: any;
    _playStateChangeSub: Subscription = null;
    _finishedPlayingSub: Subscription = null;

    @Output()
    newChatEmbed: Subject<any> = new Subject<any>();

    @Output()
    newPopOutURL: Subject<any> = new Subject<any>();

    @Output()
    newSession: Subject<PlaybackSession> = new Subject<PlaybackSession>();

    totalWatchTime: number = 0;

    private accrueWatchTime(stillWatching: boolean) {
        if (this.watchingSince) {
            let delta = (Date.now() - this.watchingSince.getTime()) / 1000.0;
            this.accruedWatchTime += delta * this.speed;
            this.totalWatchTime += delta * this.speed;
        }

        if (stillWatching)
            this.watchingSince = new Date();
        else
            this.watchingSince = null;
    }

    seekBack() {
        if (!this._session)
            return;

        this._session.seek(Math.max(0, this._session.position - 15));

        this.analytics.sendBeacons(`Seeked Back (15s)`, () => {
            this.analytics.sendBeaconGA4('tytcom_playback_seeked_back', {
                assetProvider: this.media.asset.provider,
                assetID: this.media.asset.identifier,
                assetUrl: this.media.asset.url,
                mediaType: this.media.type,
                mediaId: this.media.id,
                playlistType: this.playlistID
            }
            );
        });
    }

    seekForward() {
        if (!this._session)
            return;

        this._session.seek(Math.min(this.length, this._session.position + 15));

        this.analytics.sendBeacons(`Seeked Forward (15s)`, () => {
            this.analytics.sendBeaconGA4('tytcom_playback_seeked_forward', {
                assetProvider: this.media.asset.provider,
                assetID: this.media.asset.identifier,
                assetUrl: this.media.asset.url,
                mediaType: this.media.type,
                mediaId: this.media.id,
                playlistType: this.playlistID
            });
        });
    }

    get supportsPictureInPicture() {
        return this.session?.supportsPictureInPicture ||
            this.hostApi.hasCapabilitySync('media:picture_in_picture')
        ;
    }

    get preferredVolume(): number {
        let key = 'preferredVolume';
        if (this.assetType == 'podcast')
            key = 'preferredAudioVolume';

        let value = parseFloat(this.localStorage.get<string>(key));

        if (isNaN(value) || value === null || value === undefined)
            value = 1;

        if (this.assetType == 'podcast')
            value = Math.max(0.1, value);

        if (typeof value === 'number')
            return value;

        return 1.0;
    }

    set preferredVolume(value: number) {
        let key = 'preferredVolume';
        if (this.assetType == 'podcast')
            key = 'preferredAudioVolume';

        this.localStorage.set(key, value);
    }

    async disconnectSession(destroy = true) {
        if (this._session) {
            if (destroy) {
                try {
                    if (this._session.pauseOnDestroy !== false)
                        this._session.pause();
                } catch (e) {
                    this.logger.error(`Caught error while trying to pause session:`);
                    this.logger.error(e);
                }

                try {
                    this._session.destroy();
                } catch (e) {
                    this.logger.error(`Caught error while trying to destroy session:`);
                    this.logger.error(e);
                }
            }

            this.playback.unregisterSession(this.session);
            this._session = null;
            this.newSession.next(this._session);
        }

        if (this.historyUpdater) {
            this.periodicTasks.cancel(this.historyUpdater);
            this.historyUpdater = null;
        }

        this.sessionSubscriptions.unsubscribeAll();
        this.watchingSince = null;

        // ...and reset the watch time tracking state.
        this.accruedWatchTime = 0;
        this.totalWatchTime = 0;
        this.rawPosition = 0;
    }

    hideSidebar() {
        this.hasSidebar = false;
    }

    showSidebar() {
        this.hasSidebar = true;
    }

    @HostBinding('class.is-fullscreen')
    fullscreen: boolean = false;

    pip: boolean = false;
    hasSidebar: boolean = false;
    playing: boolean = false;
    hasPlayed: boolean = false;
    buffering: boolean = false;
    asset: ApiAvAsset;
    media: ApiPodcast | ApiVOD | ApiLiveStream;
    idle: boolean = false;
    mouseActive: boolean = false;

    get displayBuffering() {
        return this.buffering || this.playback.globalBuffering;
    }
    /**
     * Progress through the media from 0 (start) to 1 (end)
     */
    seekPosition: number = 0;
    volume: number = 1;
    muted: boolean = false;
    fastIdleTransition: boolean = false;

    private _paywallVisible: Subject<boolean> = new Subject<boolean>();

    @Output()
    public get paywallVisible(): Observable<boolean> {
        return this._paywallVisible;
    }

    private idleTime = 1000 * 3;
    private idleTimeout = null;

    isTouchDevice() {
        return 'ontouchstart' in window        // works on most browsers
            || navigator.maxTouchPoints;       // works on IE10/11 and Surface
    };

    get showChat() {
        return this.assetType === 'live_stream' && (this.media as ApiLiveStream)?.active;
    }

    showControlsClick() {
        // if (this.isTouchDevice()) {
        //     setTimeout(() => {
        //         console.log(`SHOWCTRL`);
        //         this.idle = false;
        //         this.startIdleTimeout();
        //     }, 10);
        // }
    }

    lastVideoClick: { xPercent: number, time: number } = null;

    toggleClick($event: MouseEvent) {
        if (this.isTouchDevice()) {
            $event.stopPropagation();

            if (!this.playing && !this.idle)
                return;

            console.log(`TOGGLE CLICK`);
            this.idle = !this.idle;
            this.fastIdleTransition = true;

            this.startIdleTimeout();
        }

        let element = <HTMLElement>$event.target;
        let xPercent = $event.offsetX / element.clientWidth;
        let click = { xPercent, time: Date.now() };
        let doubleClickInterval = 250; // in ms

        if (this.lastVideoClick && Date.now() - this.lastVideoClick.time < doubleClickInterval) {
            if (click.xPercent < 0.40) {
                // seek back
                this.seekBack();
            } else if (click.xPercent > 0.60) {
                // seek forward
                this.seekForward();
            }
        }

        this.lastVideoClick = click;
    }

    mouseEnter() {
        if (this.isTouchDevice())
            return;
        //console.log(`MOUSE ENTER`);
        this.idle = false;
        this.startIdleTimeout();
        this.mouseActive = true;
    }

    mouseDoubleClick() {
        this.goFullscreen(true, true);
    }

    private mouseMoveCount = 0;

    mouseDown() {
        this.mouseMoveCount = 0;
    }

    mouseMove() {
        // Touch devices will deliver one mouse move event before a mouse down event.
        // To avoid triggering the mouseMove functionality on touch, we wait for at least the second event
        // since we've last seen a mousedown event. At this point, we know we are using a proper mouse.

        this.mouseMoveCount += 1;
        if (this.mouseMoveCount < 2)
            return;

        this.idle = false;
        this.startIdleTimeout();
    }

    clearIdleTimeout() {
        if (this.idleTimeout)
            clearTimeout(this.idleTimeout);
    }

    @Input() disableControls = false;

    disableAutoHide = false;

    get controlsAlwaysVisible() {
        return this.disableAutoHide || this.playback.lockControlsVisible;
    }

    get showDevTools() {
        return environment.showDevTools;
    }

    startIdleTimeout() {
        this.clearIdleTimeout();

        this.idleTimeout = setTimeout(() => {

            // Don't hide controls when the player is not actively playing.
            if (!this.playing) {
                return;
            }

            this.fastIdleTransition = false;
            setTimeout(() => {
                this.idle = true;
            }, 10);
        }, this.idleTime);
    }

    mouseLeave() {
        if (this.isTouchDevice())
            return;
        //console.log(`MOUSE LEAVE`);
        this.idle = true;
        this.mouseActive = false;
        this.clearIdleTimeout();
    }

    goFullscreen(pushState = false, trueFullscreen = false) {
        if (pushState && isClientSide()) {
            window.history.pushState({ type: 'player', player: 'full' }, '');
        }

        this.fullscreen = true;
        this.mouseActive = false;
        this.startIdleTimeout();
        this.registerKeyboardHandler();

        if (trueFullscreen) {
            try {
                browserRequestFullscreen(this.playerContainerElement.nativeElement);
            } catch (e) {
                this.logger.error(`Could not enter fullscreen, please check your browser's site permissions.`);
            }
        }
    }

    exitFullscreen(pushState = false) {
        if (pushState && isClientSide()) {
            window.history.pushState({}, '');
        }

        // If we are in the browser's fullscreen state, we should exit that state, but not fully exit our own
        // fullscreen experience. This allows a user to have an immersive tab experience.
        if (isClientSide() && this.trueFullscreen) {
            browserExitFullScreen();
            return;
        }

        this.fullscreen = false;
        this.mouseActive = false;
    }

    @ViewChild('playerContainer') playerContainerElement: ElementRef<HTMLElement>;

    private keyboardHandler;

    private registerKeyboardHandler() {
        this.keyboardHandler = (ev: KeyboardEvent) => {
            if (this.shell.dialog)
                return;

            if (!this.session)
                return;

            if (!this.allowKeyboardControls)
                return;

            let element: HTMLElement = <any>ev.target;

            // Do not consume space if the key was pressed within Banta
            if (['textarea', 'input', 'button'].includes(element.nodeName.toLowerCase()))
                return;

            let keysEnabled = false;
            if (this.fullscreen)
                keysEnabled = true;

            if (document.documentElement.scrollTop < 300)
                keysEnabled = true;

            if (!keysEnabled)
                return;

            let handled = true;

            if (ev.keyCode == 32) {
                this.togglePlay();
                handled = true;
            } else if (ev.keyCode == 48) {
                // Go to start
                this.seekTo(0);
            } else if (ev.keyCode >= 49 && ev.keyCode <= 57) {
                let pos = (ev.keyCode - 48) / 10.0;
                this.seekTo(pos);
            } else if (ev.keyCode == 37) {
                // key=ArrowLeft
                if (this.length > 0) {
                    let seconds = 1 / this.length;
                    let seekDest = Math.max(0, this.seekPosition - 5 * seconds);
                    if (!isNaN(seekDest))
                        this.seekTo(seekDest);
                }
            } else if (ev.keyCode == 39) {
                // key=ArrowRight
                if (this.length > 0) {
                    let seconds = 1 / this.length;
                    let seekDest = Math.min(1, this.seekPosition + 5 * seconds);

                    if (!isNaN(seekDest))
                        this.seekTo(seekDest);
                }
            } else {
                handled = false;
            }

            if (handled) {
                ev.stopPropagation();
                ev.preventDefault();
            }
        };

        if (typeof document !== 'undefined')
            document.addEventListener('keydown', this.keyboardHandler);
    }

    private removeKeyboardHandler() {
        if (typeof document !== 'undefined')
            document.removeEventListener('keydown', this.keyboardHandler);
    }

    play() {
        if (!this.playback.takePlayback(this))
            return;

        this._session.resume();
        this.analytics.sendBeacons(`Start media playback (via big Play button)`, () => {
            this.analytics.sendBeaconGA4('tytcom_playback_started', {
                assetProvider: this.media.asset.provider,
                assetID: this.media.asset.identifier,
                assetUrl: this.media.asset.url,
                mediaType: this.media.type,
                mediaId: this.media.id,
                playlistType: this.playlistID,
                via: 'big-play'
            }
            );
        });
        this.startIdleTimeout();
    }

    togglePlay() {
        if (this._session) {
            if (this.playing) {
                this._session.pause();
                this.analytics.sendBeacons(`Paused playback`, () => {
                    this.analytics.sendBeaconGA4('tytcom_playback_paused', {
                        assetProvider: this.media.asset.provider,
                        assetID: this.media.asset.identifier,
                        assetUrl: this.media.asset.url,
                        mediaType: this.media.type,
                        mediaId: this.media.id,
                        playlistType: this.playlistID
                    });
                });

            } else {
                let hasPlayed = this.hasPlayed;
                if (!this.playback.takePlayback(this)) {
                    this.snackBar.open(`Cannot play media at this time.`, undefined, { duration: 3000 });
                    return;
                }

                this._session.resume();

                if (!hasPlayed) {
                    this.analytics.sendBeacons(`Start media playback`, () => {
                        this.analytics.sendBeaconGA4('tytcom_playback_started', {
                            assetProvider: this.media.asset.provider,
                            assetID: this.media.asset.identifier,
                            assetUrl: this.media.asset.url,
                            mediaType: this.media.type,
                            mediaId: this.media.id,
                            playlistType: this.playlistID,
                            via: 'controls'
                        });
                    });
                } else {
                    this.analytics.sendBeacons(`Resumed playback`, () => {
                        this.analytics.sendBeaconGA4('tytcom_playback_resumed', {
                            assetProvider: this.media.asset.provider,
                            assetID: this.media.asset.identifier,
                            assetUrl: this.media.asset.url,
                            mediaType: this.media.type,
                            mediaId: this.media.id,
                            playlistType: this.playlistID
                        });
                    });
                }
            }
        }
        //this.playing = !this.playing;
    }

    setVolume(level, auto = false) {
        if (!this._session)
            return;

        if (isNaN(level) || level === null || level === undefined)
            return;

        let oldVolume = this._session.volume;
        this._session.setVolume(level);

        if (!auto) {
            this.analytics.sendBeacons(`Set volume`, () => {
                this.analytics.sendBeaconGA4('tytcom_playback_volume', {
                    assetProvider: this.media.asset.provider,
                    assetID: this.media.asset.identifier,
                    assetUrl: this.media.asset.url,
                    mediaType: this.media.type,
                    mediaId: this.media.id,
                    previousVolume: Math.round(oldVolume * 100),
                    newVolume: Math.round(level * 100)
                });
            });
        }
    }

    private delayedSavePreferredVolume;

    seekTo(position: number) {
        let oldPos = this.session.position;

        this.seekPosition = position;
        this.seekPreviewFlag = false;
        if (!this._session || !this._session.length)
            return;

        this._session.seek(this.seekPosition * this._session.length);

        this.analytics.sendBeacons(`Seeked`, () => {
            this.analytics.sendBeaconGA4('tytcom_playback_seek', {
                assetProvider: this.media.asset.provider,
                assetID: this.media.asset.identifier,
                assetUrl: this.media.asset.url,
                mediaType: this.media.type,
                mediaId: this.media.id,
                playlistType: this.playlistID,
                fromPosition: oldPos,
                toPosition: position
            });
        });
    }

    seekPreviewFlag: boolean = false;
    seekPreviewValue: number = 0;

    seekPreviewTo(position: number) {
        this.seekPreviewFlag = true;
        this.seekPreviewValue = (position || 0) * this.length;
    }

    miniClick() {
        if (this.fullscreen)
            return;

        this.goFullscreen(true);
    }

    get upgradeUrl() {
        return `${environment.urls.accounts}/membership/change`;
    }


    public enabledClosedCaption: boolean;

    toggleClosedCaption(): void {
        if (!this.session.showClosedCaptions) {
            this.snackBar.open('Closed captioning is not currently available', undefined, { duration: 3000 });
            return;
        }
        this.session.showClosedCaptions(!this.enabledClosedCaption);
    }

    async enterPictureInPicture() {
        if (await this.hostApi.hasCapability('media:picture_in_picture')) {
            this.hostApi.sendMessage({
                type: 'pip'
            })
        } else {
            this.session.enterPictureInPicture?.();
        }
    }

    get urlForComments() {
        if (!this.media)
            return undefined;

        if (['clips', 'episodes', 'podcasts'].includes(this.playlistID))
            return this.productionsService.getWatchUrlSync(this.production, this.playlistID, <ApiVOD | ApiPodcast>this.media, true);
        else if (this.assetType === 'live_stream') {
            let stream = <ApiLiveStream>this.media;
            let date = new Date(stream.started);
            let dateStr = date.toISOString().substring(0, 10);
            return `${environment.urls.webRoot}/live/streams/${stream.id}`;
        }

        return undefined;
    }

    @ViewChild('sidebarTabs')
    sidebarTabs: MatTabGroup;

    focusOnConversation() {
        this.fullscreen = true;
        this.hasSidebar = true;

        if (this.sidebarTabs) {
            let tabs = this.sidebarTabs._tabs.toArray();
            let index = this.sidebarTabs._tabs.toArray().findIndex(x => x.textLabel === 'Conversation');
            this.sidebarTabs.selectedIndex = index;
        }
    }

    get defaultWatchExternallyLabel() {
        return 'Watch Original';
    }

    watchExternally() {
        this.analytics.sendBeacons(`Clicked '${this.session.watchExternalLabel ?? this.defaultWatchExternallyLabel}' (watch externally)`, () => {
            this.analytics.sendBeaconGA4('watch_externally', {
                label: this.session.watchExternalLabel ?? this.defaultWatchExternallyLabel
            });
        });

        this.session.pause();
        this.session.watchExternally?.();
    }

    sidebarSize: 'small' | 'medium' | 'large' = 'small';

    enlargeSidebar() {
        if (this.sidebarSize === 'small')
            this.sidebarSize = 'medium';
        else if (this.sidebarSize === 'medium')
            this.sidebarSize = 'large';
        else
            this.sidebarSize = 'small';
    }

    shrinkSidebar() {
        if (this.sidebarSize === 'medium')
            this.sidebarSize = 'small';
        else if (this.sidebarSize === 'large')
            this.sidebarSize = 'medium';
        else
            this.sidebarSize = 'large';
    }

    get vodOrPodcast(): ApiVOD | ApiPodcast | undefined {
        if (!this.media)
            return undefined;

        if (this.media.type === 'live_stream')
            return undefined;
        return <ApiVOD | ApiPodcast>this.media;
    }

    get contentTypeLabel() {
        if (this.playlistID === 'episodes')
            return 'part';
        if (this.playlistID === 'clips')
            return 'clip';
        if (this.playlistID === 'podcasts')
            return 'part';
        if (this.playlistID === 'audio-stories')
            return 'audio story';

        return 'video';
    }
}

function browserExitFullScreen() {
    if (document['fullscreenElement']) {
        try {
            if (document.exitFullscreen)
                document.exitFullscreen();
            else if (document['webkitCancelFullScreen'])
                document['webkitCancelFullScreen']();
            else if (document['webkitExitFullscreen'])
                document['webkitExitFullscreen']();
            else if (document['mozCancelFullScreen'])
                document['mozCancelFullScreen']();
            else if (document['msExitFullscreen'])
                document['msExitFullscreen']();
        } catch (e) {
            // We may get an error like "Document not active" here, but
            // it's safe to ignore.
        }
    }
}

function browserRequestFullscreen(element: HTMLElement) {

    if (element.requestFullscreen)
        element.requestFullscreen();
    else if (element['webkitRequestFullscreen'])
        element['webkitRequestFullscreen']();
    else if (element['mozRequestFullScreen'])
        element['mozRequestFullScreen']();
    else if (element['msRequestFullscreen'])
        element['msRequestFullscreen']();
}
