import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ApiAvAsset } from '@tytapp/api';
import { isServerSide } from '@tytapp/environment-utils';
import { UserService } from '@tytapp/user';

@Component({
  selector: 'no-js-media-player',
  templateUrl: './no-js-media-player.component.html',
  styleUrls: ['./no-js-media-player.component.scss']
})
export class NoJsMediaPlayerComponent implements AfterViewInit {

  @ViewChild('videoPlayer', {static: true}) videoPlayerElement : ElementRef<HTMLVideoElement>;

  @Input() asset: ApiAvAsset;
  @Input() type: 'audio' | 'video';

  public viewReady : Promise<void>;
  private fireViewReady : () => void;

  constructor(
    private readonly userService: UserService
  ) {
    this.viewReady = new Promise((res) => this.fireViewReady = res);
    this.load();
  }

  enable = false;

  async load() {
    await this.viewReady;
  }

  ngOnInit() {
    this.enable = isServerSide();
  }

  ngAfterViewInit(): void {
    this.fireViewReady();
  }

  play(asset: ApiAvAsset) {
    this.asset = asset;
  }

  get videoURL(): string {
    return this.asset?.hd_video_download_url || this.asset?.sd_video_download_url;
  }

  get videoTextTrackURL(): string | null {
    return this.asset?.vtt_url;
  }

  get isMember(): boolean {
    return !!this.userService.entitled;
  }

}
