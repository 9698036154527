import { Component, HostBinding, inject } from '@angular/core';
import { PageComponent } from '@tytapp/common';
import { AirPlayService } from '../airplay.service';

@Component({
    selector: 'tyt-airplay-button',
    templateUrl: './airplay-button.component.html',
    styleUrls: ['./airplay-button.component.scss']
})
export class AirplayButtonComponent extends PageComponent {
    private airplayService = inject(AirPlayService);

    available: boolean = false;
    connected: boolean = false;

    @HostBinding('class.hidden')
    get isVisible() {
        return !this.available && !this.connected;
    }

    get isAudio() {
        return this.airplayService.isAudio;
    }

    async init() {
        this.subscribe(this.airplayService.availableChanged, available => this.available = available);
        this.subscribe(this.airplayService.connectedChanged, connected => this.connected = connected);
    }

    click() {
        this.airplayService.pickRoute();
    }
}
