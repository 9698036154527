import { Injectable, inject } from "@angular/core";
import { ApiAvAsset } from '@tytapp/api';
import { AssetResolution } from "./asset-resolution";
import { MediaItem } from "./media-item";
import { MediaService } from "./media-service";
import { LoggerService } from '@tytapp/common';

@Injectable()
export class MediaServiceRegistry {
    private logger = inject(LoggerService);
    private _services: MediaService[] = [];

    register(mediaService: MediaService) {
        this._services.push(mediaService);
    }

    get all() {
        return this._services.slice(0);
    }

    /**
     * Find a service which can play back the given media
     */
    resolve(playbackItem: MediaItem, useCachedResolution: boolean = true): AssetResolution {
        let asset: ApiAvAsset = playbackItem.item.asset;

        if (asset == null) {
            debugger;
            throw new Error("Cannot resolve null media");
        }

        if (useCachedResolution && asset['resolution'])
            return asset['resolution'];

        let resolution: AssetResolution = null;
        let orderedServices = this.all.slice(0).sort((a, b) => ((b.priority || 0) - (a.priority || 0)));

        orderedServices.find(x => null != (resolution = x.resolve(playbackItem)));

        if (!resolution) {
            this.logger.error(`Playback: Error: Could not locate service capable of playing:`);
            this.logger.inspect(playbackItem);
            return null;
        } else {
            this.logger.info(`Playback: Selected service ${resolution.service.id} for playback`);
        }

        resolution.url = asset.url;

        return resolution;
    }
}