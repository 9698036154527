<div class="player-box" [class.has-paywall]="paywallVisible" [class.hidden]="!paywallVisible && mode == 'podcasts'">
  <tyt-media-player
    #player
    [inline]="true"
    [autoplay]="autoplay"
    (paywallVisible)="paywallVisible = $event"
    [inEditor]="inEditor"
    [embed]="embed"
    (playbackRevoked)="playbackRevoked.next()"
    (currentItemChanged)="MediaTypes.isLiveStream($event) ? undefined : updateItem($event)">
  </tyt-media-player>

  @if (!enableNoJsMediaPlayer) {
    <noscript>
      You must enable Javascript to watch this video
    </noscript>
  }
  @if (enableNoJsMediaPlayer) {
    <no-js-media-player
      [asset]="currentlyPlayingVOD?.asset || currentlyPlayingPodcast?.asset"
      [type]="currentlyPlayingVOD ? 'video' : 'audio'"
      >
    </no-js-media-player>
  }
</div>
@if (mode === 'podcasts' && !paywallVisible && item && showDescription) {
  <div class="podcast-player">
    @if (item) {
      <div class="current-item">
        <div class="production-date">{{production.date_simple | date : 'mediumDate'}}</div>
        <h1>
          {{item.title}}
        </h1>
        <div>
          @if (item.is_premium) {
            <tyt-plus-tag [state]="item.unlock_premium ? 'unlocked' : 'locked'"></tyt-plus-tag>
          }
        </div>
        <ul class="stats">
          <li>{{item.duration | duration}}</li>
          <li>
            <tyt-comments-count [identifier]="topicIdentifier"></tyt-comments-count>
          </li>
          @if (showEditLink) {
            <li><a routerLink="/watch/edit/{{production.id}}/{{mode}}/{{item.id}}">Edit</a></li>
          }
        </ul>
      </div>
    }
    <tyt-remote-audio-player></tyt-remote-audio-player>
  </div>
}

<mat-menu #playbackOptionsMenu>

  @if (!entitled) {
    @if (mode === 'podcasts') {
      <a mat-menu-item routerLink="{{loginOrJoinUrl}}">
        <mat-icon>download_for_offline</mat-icon>
        Download [members only]
      </a>
    }
    @if (mode !== 'podcasts') {
      <a mat-menu-item routerLink="{{loginOrJoinUrl}}">
        <mat-icon>download_for_offline</mat-icon>
        Download HD [members only]
      </a>
      <a mat-menu-item routerLink="{{loginOrJoinUrl}}">
        <mat-icon>download_for_offline</mat-icon>
        Download SD [members only]
      </a>
      <a mat-menu-item routerLink="{{loginOrJoinUrl}}">
        <mat-icon>download_for_offline</mat-icon>
        Download Audio [members only]
      </a>
    }
  }
  @if (entitled) {
    @if (downloadsEnabled) {
      @for (downloadType of downloadTypes; track downloadTypeId(downloadType)) {
        @if (!downloadType.download) {
          <a
            mat-menu-item
            [class.unavailable]="!downloadType.asset"
            href="javascript:;"
            (click)="startDownload(item, downloadType.asset)"
            >
            <mat-icon>download_for_offline</mat-icon>
            Download ({{ downloadType.label }})
            @if (!downloadType.asset) {
              <span class="not-available-text">[not
              available]</span>
            }
          </a>
        }
        @if (downloadType.download) {
          <a mat-menu-item routerLink="/downloads">
            <mat-icon>done</mat-icon>
            @if (downloadType.download.status === 'completed') {
              Downloaded
            }
            @if (downloadType.download.status === 'downloading') {
              Downloading ({{progressFor(downloadType.download)}}%)
            }
          </a>
        }
      }
    }
    @for (downloadType of downloadTypes; track downloadType) {
      <a
        mat-menu-item
        [class.unavailable]="!downloadType.asset"
        href="{{downloadType.asset}}" title="Right click or long tap to download"
        target="_blank"
        download>
        <mat-icon>download_for_offline</mat-icon>
        Download File ({{downloadType.label}})
        @if (!downloadType.asset) {
          <span class="not-available-text">[not
          available]</span>
        }
      </a>
    }
    <div class="download-explanation">
      Note: For File links you may need to right click or long tap on the link and select 'Save
      link as'
    </div>
  }
</mat-menu>

@if (mode !== 'podcasts' && item && showDescription) {
  <div class="current-item">
    @if (item && currentlyPlayingVOD?.asset) {
      <div id="play-options">
        <button mat-button [matMenuTriggerFor]="playbackOptionsMenu" aria-label="Playback Options" matTooltip="Playback Options">
          <mat-icon>download_for_offline</mat-icon>
        </button>
      </div>
    }
    <div class="production-date">{{production.date_simple | date : 'mediumDate'}}</div>
    <h1>
      {{item.title}}
    </h1>
    <div class="description cms-content" [innerHTML]="item.description | newlinesToHtml | linksToHtml | trustHtml"></div>
    <div>
      @if (item.is_premium) {
        <tyt-plus-tag [state]="item.unlock_premium ? 'unlocked' : 'locked'"></tyt-plus-tag>
      }
    </div>
    <ul class="stats">
      <li>{{item.duration | duration}}</li>
      <li>
        <tyt-comments-count [identifier]="topicIdentifier"></tyt-comments-count>
      </li>
      @if (showEditLink) {
        <li><a routerLink="/watch/edit/{{production.id}}/{{mode}}/{{item.id}}">Edit</a></li>
      }
    </ul>
    <mat-accordion>
      @if (transcript) {
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Transcript
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="transcript">{{ transcript }}</div>
        </mat-expansion-panel>
      }
    </mat-accordion>
  </div>
}

@if (show && mode == 'podcasts') {
  <div>
    @if (show) {
      <div class="podcast-providers">
        <span class="available">Available on </span>
        @for (provider of getPodcastProviders(show); track provider) {
          <a
            class="brick"
            [attr.aria-label]="provider.name"
            title="{{provider.name}}" target="_blank" href="{{provider.url}}">
            <img [src]="provider.logo_brick || provider.logo_square || provider.logo">
          </a>
        }
        <a mat-icon-button routerLink="/podcasts/{{show.id}}"
          aria-label="Get the members-only podcast on your favorite app"
          title="Members-only Podcast">
          <mat-icon>rss_feed</mat-icon>
        </a>
        <button mat-icon-button [matMenuTriggerFor]="playbackOptionsMenu"
          aria-label="Download this podcast for offline listening"
          title="Download"
          >
          <mat-icon>download_for_offline</mat-icon>
        </button>
      </div>
    }
  </div>
}

@if (showSocialButtons && mode != 'podcasts') {
  <tyt-social-buttons></tyt-social-buttons>
}

@if (showComments || showPlaylist) {
  <div class="split-view">
    @if (topicIdentifier && showComments) {
      <div class="comments">
        <tyt-comments
          [url]="canonicalUrl"
          [identifier]="topicIdentifier"
          [shareTitle]="topicTitle"
        ></tyt-comments>
      </div>
    }
    @if (showPlaylist) {
      <mat-tab-group mat-stretch-tabs="false" [(selectedIndex)]="selectedModeTab">
        <mat-tab label="Now Playing ({{ modeLabel }})">
          <tyt-production-view
            class="mini"
            [production]="production"
            [mode]="mode"
            [playInline]="true"
            [playRemote]="true"
            [showImage]="false"
            [showFeedTag]="false"
            [currentlyPlayingItem]="item"
            (playClicked)="playItem($event, mode)"
          ></tyt-production-view>
        </mat-tab>
        @if (mode !== 'episodes') {
          <mat-tab label="Episode">
            <tyt-production-view
              class="mini"
              [production]="production"
              mode="episodes"
              [playInline]="true"
              [playRemote]="true"
              [showImage]="false"
              [showFeedTag]="false"
              [currentlyPlayingItem]="item"
              (playClicked)="playItem($event, 'episodes')"
            ></tyt-production-view>
          </mat-tab>
        }
        @if (mode !== 'clips') {
          <mat-tab label="Clips">
            <tyt-production-view
              class="mini"
              [production]="production"
              mode="clips"
              [playInline]="true"
              [playRemote]="true"
              [showImage]="false"
              [showFeedTag]="false"
              [currentlyPlayingItem]="item"
              (playClicked)="playItem($event, 'clips')"
            ></tyt-production-view>
          </mat-tab>
        }
        @if (mode !== 'podcasts') {
          <mat-tab label="Podcast">
            <tyt-production-view
              class="mini"
              [production]="production"
              mode="podcasts"
              [playInline]="true"
              [playRemote]="true"
              [showImage]="false"
              [showFeedTag]="false"
              [currentlyPlayingItem]="item"
              (playClicked)="playItem($event, 'podcasts')"
            ></tyt-production-view>
          </mat-tab>
        }
      </mat-tab-group>
    }
  </div>
}

<ng-content selector=".sidebar"></ng-content>