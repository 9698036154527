import { inject } from '@angular/core';
import { ApiPodcast, ApiProduction, ApiVOD } from '@tytapp/api';
import { LoggerService } from '@tytapp/common';
import { HostApi, Request, Response } from '@tytapp/common';
import { Subject } from 'rxjs';
import { Download } from '../download';
import { DownloadManager } from '../download-manager';

interface StartDownloadReq {
    type: 'download';
    kind: 'vod' | 'podcast';
    cfid: string;
    asset_url: string;
    production: string;
    title?: string;
    description?: string;
}

type NativeDownload = Omit<Download, 'production'> & { production: string }
interface ListDownloadsReq extends Request { type: 'get_downloads'; }
interface ListDownloadsRes extends Response { downloads: NativeDownload[]; }

interface DeleteDownloadReq {
    type: 'delete_download';
    request_id: string;
    id: string;
}

interface DeleteDownloadRes {
    type: 'result';
    request_type: 'delete_download';
    request_id: string;
    status: 'success' | 'failure';
    errorMessage: string;
}

interface PlayDownloadReq {
    type: 'play_download';
    id: string;
}

interface DownloadStatusMessage {
    type: 'download_status';
    download: NativeDownload;
}

function isDownloadStatus(message: { type: string }): message is DownloadStatusMessage {
    return message.type == 'download_status';
}

export class NativeDownloadManager implements DownloadManager {
    constructor(
        private readonly hostApi: HostApi,
        private logger: LoggerService
    ) {
        this.hostApi.messageReceived.subscribe(message => {
            if (isDownloadStatus(message)) {
                this._downloadUpdated.next({ ...message.download, production: JSON.parse(message.download.production) })
            }
        });
    }

    readonly id = 'native';

    async list(): Promise<Download[]> {
        return (await this.hostApi.sendRequest<ListDownloadsReq, ListDownloadsRes>({ type: 'get_downloads' }))
            .downloads
            .map(nd => {
                let production: ApiProduction;
                try {
                    production = JSON.parse(nd.production)
                } catch (e) {
                    this.logger.error(`[Downloads] Invalid production received from native download manager:`);
                    this.logger.error(nd.production || '<empty>');
                }

                return { ...nd, production: production };
            })
        ;
    }

    async start(production: ApiProduction, item: ApiVOD | ApiPodcast, asset_url: string): Promise<Download> {
        const download = {
            id: item.id,
            cfid: item.id,
            completed: false,
            progress: 0,
            kind: <'vod'|'podcast'>item.type,
            production,
            asset_url
        };
        await this.restart(download);
        return download;
    }

    async restart(download: Download): Promise<void> {
        this.hostApi.sendMessage<StartDownloadReq>({
            type: 'download',
            kind: download.kind,
            cfid: download.cfid,
            asset_url: download.asset_url,
            production: JSON.stringify(download.production),
            title: this.getTitleForDownload(download),
            description: this.getDescriptionForDownload(download)
        });
    }

    private getItemForDownload(download: Download): ApiVOD | ApiPodcast {
        return download.production.full_length_vods.find(x => x.id === download.cfid)
            ?? download.production.vod_clips.find(x => x.id === download.cfid)
            ?? download.production.full_length_podcasts.find(x => x.id === download.cfid)
            ?? download.production.audio_clips.find(x => x.id === download.cfid)
    }

    private getTitleForDownload(download: Download) {
        let production = download.production;
        let item = this.getItemForDownload(download);
        let simpleTitle = `${production?.show?.name} ${new Date(production?.date_simple).toLocaleDateString()}`;

        return item?.title ?? download.production?.title ?? simpleTitle;
    }

    private getDescriptionForDownload(download: Download) {
        let item = this.getItemForDownload(download)
        return item?.description;
    }

    async isInterrupted(download: Download): Promise<boolean> {
        return ['interrupted'].includes(download.status);
    }

    async delete(download: Download): Promise<void> {
        await this.hostApi.sendRequest<DeleteDownloadReq, DeleteDownloadRes>({
            type: 'delete_download',
            id: download.id
        });
    }

    async play(download: Download): Promise<void> {
        this.hostApi.sendMessage<PlayDownloadReq>({
            type: 'play_download',
            id: download.id
        });
    }

    private _downloadUpdated = new Subject<Download>();
    private _downloadUpdated$ = this._downloadUpdated.asObservable();
    get downloadUpdated() { return this._downloadUpdated$; }

    private getMimeTypeOfDownload(download: Download) {
        if (download.asset_url.endsWith('.mp3'))
            return 'audio/mp3';
        else if (download.asset_url.endsWith('.mp4'))
            return 'video/mp4';
        else if (download.asset_url.endsWith('.m4a'))
            return 'audio/m4a';

        return 'video/x-unknown';
    }

    supportsWebPlayback = true;
    async getUrlForDownload(download: Download): Promise<[ string, string ]> {

        return [ this.getMimeTypeOfDownload(download), `/downloaded-content/${download.cfid}_${download.asset_url.replace(/.*\//, '')}` ]
    }

}