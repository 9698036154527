@if (useBantaChat && platformChatEmbed) {
  <mat-tab-group mat-stretch-tabs="false" class="fullbleed" [disablePagination]="true">
    <mat-tab label="TYT.com">
      <tyt-chat class="fullbleed" identifier="live:{{stream.id}}"></tyt-chat>
    </mat-tab>
    <mat-tab label="YouTube">
      <tyt-yt-chat-native-message
        (watchExternally)="watchExternally.next()"
        (watchOnWeb)="recordWatchOnWebBeacons()"
        [watchOnWebUrl]="watchOnWebUrl"
      ></tyt-yt-chat-native-message>
      <div class="external-chat" class="fullbleed" [innerHTML]="platformChatEmbed" tabindex="-1"></div>
    </mat-tab>
  </mat-tab-group>
}
@if (useBantaChat && !platformChatEmbed) {
  <tyt-chat class="fullbleed" identifier="live:{{stream.id}}"></tyt-chat>
}
@if (!useBantaChat && platformChatEmbed) {
  <tyt-yt-chat-native-message
    (watchExternally)="watchExternally.next()"
    (watchOnWeb)="recordWatchOnWebBeacons()"
    [watchOnWebUrl]="watchOnWebUrl"
  ></tyt-yt-chat-native-message>
  <div id="chat" class="fullbleed" [innerHTML]="platformChatEmbed" tabindex="-1"></div>
}