@if (!production) {
  <div class="v-center">
    <br />
    <app-spinner></app-spinner>
  </div>
}

@if (production) {
  @if (!confirmationPage && showFeedTag) {
    <a [routerLink]="linkToWatchFirstItem" (click)="playButtonClicked($event, firstItem)">
      @if (showDate !== false && (!includeShowName || !production.show)) {
        <tyt-feed-tag>
          <time [dateTime]="date">{{date | date : 'longDate'}}</time>
        </tyt-feed-tag>
      }
      @if (includeShowName && production.show) {
        <tyt-feed-tag>{{production.show?.name}}, {{date | date : 'longDate'}}
        </tyt-feed-tag>
      }
    </a>
  }
  <div class="production-view {{ confirmationPage? 'confirmation-page-view' : '' }} ">
    @if (includeMetadata || includeItems) {
      <div id="underbox">
        @if (includeMetadata) {
          @if (!production.show || production.show?.use_titles) {
            <h1>
              @if (!playInline) {
                <a [routerLink]="linkToWatchFirstItem" (click)="playButtonClicked($event, firstItem)">
                  @if (!production.is_published) {
                    <span class="draft">Draft</span>
                  }
                  {{production.title || 'Untitled'}}
                </a>
              }
              @if (playInline) {
                <a href="javascript:;" (click)="playButtonClicked($event, firstItem)">
                  @if (!production.is_published) {
                    <span class="draft">Draft</span>
                  }
                  {{production.title || 'Untitled'}}
                </a>
              }
            </h1>
          }
          @if (includeTalent) {
            <div id="metadata">
              @if (production.hosts && production.hosts.length > 0) {
                <strong>Hosts:&nbsp;</strong>
                <span>
                  @for (host of production.hosts; track host) {
                    <a [routerLink]="(host.username && shouldShowTalentUser) ? '@/'+host.username : '/about/talent/'+host.id">{{host.name}}</a><span class="comma"></span>
                  }
                </span>
              }
              @if (production.guests && production.guests.length > 0) {
                &nbsp;
                <strong>Guests:&nbsp;</strong>
                <span>
                  @for (guest of production.guests; track guest) {
                    <a>{{guest.name}}</a><span class="comma"></span>
                  }
                </span>
              }
            </div>
          }
        }
        @if (includeItems) {
          <div id="items">
            @for (item of items; track item; let i = $index) {
              @if (!showOnlyItem || showOnlyItem.id === item.id) {
                <a
                  role="button"
                  class="item"
                  [class.btn-play]="!isPlaying(item)"
                  [class.btn-playing]="isPlaying(item)"
                  [class.active]="isPlaying(item)"
                  [class.watched]="sessions[item.id] && sessions[item.id].position"
                  (click)="playButtonClicked($event, item)"
                  [href]="watchUrl(item)"
                  [style.animation-delay]="(i*100)+'ms'"
                  [attr.aria-label]="(isPlaying(item) ? 'Current' : 'Play') + ' ' + itemLabel + ': ' + item.description"
                  >
                  @if (!isPlaying(item)) {
                    <mat-icon>play_arrow</mat-icon>
                  }
                  @if (isPlaying(item)) {
                    <mat-icon>more_horiz</mat-icon>
                  }
                  <div class="item-content">
                    <summary>
                      {{item.description || 'Untitled'}}
                    </summary>
                    <tyt-playback-status-bar (sessionChanged)="sessions[item.id] = $event" [item]="item"
                      [production]="production" [playlist]="mode">
                    </tyt-playback-status-bar>
                    <div class="addendum">
                      <div class="tyt-tags">
                        <tyt-content-badge
                          [type]="mode"
                          [premium]="item.is_premium"
                          [unlocked]="item.unlock_premium"
                          [topic]="item.topics?.[0]"
                        ></tyt-content-badge>
                      </div>
                      <ul class="facts">
                        <li>{{item.duration | duration}}</li>
                        @if (showCommentsCount) {
                          <li>
                            <tyt-comments-count identifier="vod_{{item.id}}"></tyt-comments-count>
                          </li>
                          } <!-- TODO -->
                        </ul>
                      </div>
                    </div>
                  </a>
                }
              }
            </div>
          }
        </div>
      }
    </div>
  }
