<div class="noscript-media-player">
  @if (enable && videoURL) {
    <video class="native-video-player" #videoPlayer controls [src]="videoURL">
      @if (videoTextTrackURL) {
        <track label="Video Captions" [src]="videoTextTrackURL" srclang="en" kind="captions"
          default>
      }
    </video>
  }

  @if (!videoURL) {
    <div class="unavailable">
      @if (isMember) {
        <div class="alert alert-warning">
          This media is not yet available when Javascript is not enabled. Please enable Javascript to watch this content
          or check back later.
        </div>
      }
      @if (!isMember) {
        <div class="alert alert-warning">
          You must enable Javascript to watch this media. Some videos are available without Javascript for TYT members.
        </div>
      }
    </div>
  }
  <tyt-staging-note>
    <tyt-json-view [value]="asset"></tyt-json-view>
  </tyt-staging-note>
</div>