<div class="seek-bar">
  <tyt-cast-button></tyt-cast-button>
  <tyt-airplay-button></tyt-airplay-button>
  <span class="position-text">{{seekPosition | duration : 'short-seconds'}}</span>
  <player-seek-bar [seekPosition]="seekPercentage" (seek)="seek($event)"></player-seek-bar>
  <player-volume-button [volume]="volume" (change)="setVolume($event)"></player-volume-button>
</div>

<div class="controls">
  <a class="icon-button" href="javascript:;" (click)="playPreviousItem()">
    <mat-icon>skip_previous</mat-icon>
  </a>
  <a class="icon-button" href="javascript:;" (click)="seekBack()">
    <mat-icon>undo</mat-icon>
  </a>
  @if (playing) {
    <a class="icon-button play-button" href="javascript:;" (click)="pause()">
      <mat-icon>pause</mat-icon>
    </a>
  }
  @if (!playing) {
    <a class="icon-button play-button" href="javascript:;" (click)="resume()">
      <mat-icon style="position: relative; left: 2px;">play_arrow</mat-icon>
    </a>
  }
  <a class="icon-button" href="javascript:;" (click)="seekForward()">
    <mat-icon>redo</mat-icon>
  </a>
  <a class="icon-button" href="javascript:;" (click)="playNextItem()">
    <mat-icon>skip_next</mat-icon>
  </a>
</div>