
/**
 * @param resource
 * @returns Promise<string>
 */
export function srtToVTT(srt: string) {
    return `WEBVTT\r\n\r\n${
        srt
            .replace(/\{\\([ibu])\}/g, '</$1>')
            .replace(/\{\\([ibu])1\}/g, '<$1>')
            .replace(/\{([ibu])\}/g, '<$1>')
            .replace(/\{\/([ibu])\}/g, '</$1>')
            .replace(/(\d\d:\d\d:\d\d),(\d\d\d)/g, '$1.$2')
    }\r\n\r\n`;
};