<div
  #playerContainer
  class="player-container"
  [class.visible]="(!inline && videoDisabled) ? false : ( (media && assetType != 'podcast') || showPaywall || errorMessage )"
  [class.fullscreen]="fullscreen"
  [class.idle]="disableControls || (idle && !controlsAlwaysVisible)"
  [class.pip]="isPip"
  [class.idle-fast-transition]="fastIdleTransition"
  [class.has-sidebar]="hasSidebar && fullscreen"
  [class.sw-keyboard-visible]="softwareKeyboardVisible"
  [attr.data-is-pip]="isPip"
  [attr.data-is-inline]="isInline">

  <div class="video-experience">
    <!-- PAYWALL -->
    @if (showPaywall) {
      <div class="paywall">
        <tyt-paywall [noun]="videoLabel"></tyt-paywall>
      </div>
    }
    <!-- /PAYWALL -->

    @if (errorMessage) {
      <div class="error-message">
        <div class="message-box">
          <div class="header">{{errorTitle}}</div>
          <div class="message">
            {{errorMessage}}
          </div>
        </div>
      </div>
    }

    <div id="playback-element" #playbackElement></div>

    <div class="notifier" [class.visible]="notifying">
      <div>{{notifyMessage}}</div>
    </div>

    @if (enableControls && media && !hasPlayed && !showPaywall) {
      <a class="x-main-content" aria-label="Play video" class="big-play-container" href="javascript:;" (click)="play()">
        <div class="btn-play">
          <mat-icon>play_arrow</mat-icon>
        </div>
      </a>
    }

    @if (displayBuffering) {
      <div class="buffering-container">
        <app-spinner color="white"></app-spinner>
      </div>
    }

    @if (enableControls) {
      @if (isPip) {
        <div id="mini-overlay" (click)="miniClick()"></div>
      }
      @if (isPip && !fullscreen) {
        <a id="mini-close" href="javascript:;" (click)="hideOrDismiss()"
          aria-label="Dismiss media">
          <mat-icon>close</mat-icon>
        </a>
      }
      @if (isPip && !showPaywall) {
        <a id="mini-play" href="javascript:;" (click)="togglePlay()"
          aria-label="Toggle media playback">
          @if (playing) {
            <mat-icon>pause</mat-icon>
          }
          @if (!playing) {
            <mat-icon>play_arrow</mat-icon>
          }
        </a>
      }
    }

    @if (enableControls && !pipWindowMode && !errorMessage) {
      <div
        class="controls-ui"
        [class.visible]="!(paywallVisible | async) && (fullscreen || !isPip)"
        >
        <div class="player-controls" (click)="showControlsClick()" (doubleclick)="mouseDoubleClick()"
          (mousemove)="mouseMove()" (mousedown)="mouseDown()" (mouseleave)="mouseLeave()" (mouseenter)="mouseEnter()">
          @if (isInline || fullscreen) {
            <div
              class="controls-header fade-on-idle"
              (mouseleave)="mouseLeave()"
              (mouseenter)="mouseEnter()">
              @if (isInline) {
                <div class="spacer"></div>
              }
              @if (fullscreen) {
                <div id="close" (click)="showControlsClick()">
                  <a mat-button aria-label="Browse and Watch" class="minimize" href="javascript:;" (click)="exitFullscreen(true)">
                    <mat-icon>picture_in_picture</mat-icon>
                    Browse and Watch
                  </a>
                </div>
                <div class="spacer" (click)="toggleClick($event)">
                </div>
                @if (!hasSidebar) {
                  <a mat-button aria-label="Show sidebar" id="show-sidebar" href="javascript:;" (click)="showSidebar(); showControlsClick()">
                    <mat-icon>menu</mat-icon>
                  </a>
                }
              }
            </div>
          }
          <div class="empty-space" (click)="toggleClick($event)">
            <div class="c2a" [class.visible]="callingToAction">
              @if (c2a) {
                <div class="content">
                  <a target="_blank" [url]="c2a.url" class="box" [attr.data-style]="c2a.style">
                    @if (c2a.icon) {
                      <tyt-icon [size]="48" [icon]="c2a.icon"></tyt-icon>
                    }
                    <div class="body">
                      <div class="title">{{c2a.title}}</div>
                      <div class="message full" [innerHTML]="c2a.message | markdownToHtml | trustHtml"></div>
                      <div class="message brief" [innerHTML]="c2a.briefMessage ?? c2a.message | markdownToHtml | trustHtml"></div>
                    </div>
                  </a>
                  <div class="actions">
                    <button mat-icon-button (click)="dismissCallToAction(); $event.stopPropagation();">
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                </div>
              }
            </div>
          </div>
          <div class="overlay"></div>
          <div class="controls fade-on-idle" (mouseleave)="mouseLeave()"
            (mouseenter)="mouseEnter()" (click)="showControlsClick()">
            <div class="controls-line">
              @if (sessionSeekable) {
                <player-seek-bar [seekPreviewValue]="seekPreviewValue" [isLive]="isLive"
                  [duration]="length" [seekPosition]="seekPosition" (seekDrag)="seekPreviewTo($event)"
                (seek)="seekTo($event)"></player-seek-bar>
              }
              <button
                id="play"
                mat-icon-button
                (click)="togglePlay()"
                [attr.aria-label]="playing ? 'Pause' : 'Play'"
                [matTooltip]="playing ? 'Pause' : 'Play'"
                >
                @if (playing) {
                  <mat-icon>pause</mat-icon>
                }
                @if (!playing) {
                  <mat-icon>play_arrow</mat-icon>
                }
              </button>
              <button
                id="rewind-button"
                aria-label="Seek back 15 seconds"
                matTooltip="Seek back (15s)"
                (click)="seekBack()"
                mat-icon-button
                >
                <mat-icon>undo</mat-icon>
              </button>
              @if (hasMoreQueueItems) {
                <button
                  mat-icon-button
                  id="next"
                  (click)="skip()"
                  attr.aria-label="Skip to next {{ contentTypeLabel }}"
                  matTooltip="Skip to next {{ contentTypeLabel }}"
                  >
                  <mat-icon>skip_next</mat-icon>
                </button>
              }
              <player-volume-button [volume]="volume" (change)="setVolume($event)"></player-volume-button>
              @if (isLive) {
                <div class="spacer position"
                  attr.aria-label="Seek position: {{livePosition | duration: 'short-seconds'}} behind live"
                >-{{livePosition | duration: 'short-seconds' }}</div>
              }
              @if (!isLive) {
                <div role="text" tabindex class="spacer position"
                  attr.aria-label="Seek position: {{rawPosition | duration: 'short-seconds'}}, duration: {{length | duration: 'short-seconds'}}"
                  >
                  @if (seekPreviewFlag) {
                    {{seekPreviewValue | duration: 'short-seconds'}}
                  }
                  @if (!seekPreviewFlag) {
                    {{rawPosition | duration: 'short-seconds'}}
                  }
                  / {{length | duration: 'short-seconds'}}
                </div>
              }
              @if (asset?.vtt_url && session.showClosedCaptions) {
                <button
                  id="cc-button"
                  mat-icon-button
                  (click)="toggleClosedCaption()"
                  matTooltip="Toggle closed captioning"
                  [attr.aria-label]="enabledClosedCaption ? 'Closed captioning is enabled' : 'Closed captioning is disabled'"
                  >
                  <mat-icon>
                    {{enabledClosedCaption ? 'closed_caption' : 'closed_caption_off'}}
                  </mat-icon>
                </button>
              }
              @if (session && session.supportedPlaybackSpeeds) {
                <button
                  id="playback-speed-button"
                  mat-button
                  class="playback-rate"
                  (click)="nextPlaybackRate()"
                  attr.aria-label="Change playback speed, currently {{speed}}x"
                  title="Change playback speed"
                  >
                  {{speed}}x
                </button>
              }
              <tyt-cast-button></tyt-cast-button>
              <tyt-airplay-button></tyt-airplay-button>
              @if (!trueFullscreen) {
                <button
                  id="enter-true-fullscreen"
                  mat-icon-button
                  (click)="goFullscreen(true, true)"
                  aria-label="Full Screen"
                  title="Full Screen">
                  <mat-icon>fullscreen</mat-icon>
                </button>
              }
              @if (isLive) {
                <button
                  mat-button
                  [attr.aria-label]="isRewinded ? 'Watching DVR. Press to jump to live.' : 'Press to jump to live.'"
                  (click)="jumpToLive()" class="is-live"
                  [class.rewinded]="isRewinded"
                  title="When lit: You are watching live. When unlit: you are rewinded and watching DVR content.">
                  <span class="dot"></span>
                  LIVE
                </button>
              }
              <button mat-icon-button [matMenuTriggerFor]="overflowMenu">
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    }
  </div>

  <aside [class.small]="sidebarSize === 'small'" [class.medium]="sidebarSize === 'medium'" [class.large]="sidebarSize === 'large'">
    <div class="sidebar-controls no-portrait">
      <button mat-icon-button (click)="enlargeSidebar()" [disabled]="sidebarSize === 'large'">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <button mat-icon-button (click)="shrinkSidebar()" [disabled]="sidebarSize === 'small'">
        <mat-icon>chevron_right</mat-icon>
      </button>

      <button mat-icon-button class="close-sidebar " (click)="hideSidebar()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <mat-tab-group mat-stretch-tabs="false" class="player-sidebar-tabs" [preserveContent]="true" [disablePagination]="true" #sidebarTabs>
      @if (playlistID) {
        <mat-tab label="Playlist">
          <tyt-production-view class="mini"
            [currentlyPlayingItem]="vodOrPodcast"
            [production]="production"
            [mode]="playlistID"
            [playInline]="true"
            [playRemote]="true"
            [showImage]="false"
            [showFeedTag]="true"
            (playClicked)="playItem($event)"
            />
        </mat-tab>
      }
      <mat-tab label="About">
        @if (media && media.title) {
          <h1>{{media.title | summarize}}</h1>
        }
        @if (media && media.description) {
          <summary
            class="cms-content"
          [innerHTML]="media.description | newlinesToHtml | linksToHtml | trustHtml"></summary>
        }
      </mat-tab>
      <mat-tab label="Conversation"> <!-- CAUTION: this label is depended on in the component -->
        @if (topicIdentifier) {
          <tyt-comments
            [url]="urlForComments"
            [showHeader]="false"
            [identifier]="topicIdentifier"
            [handleNotifications]="fullscreen || !inline"
            (requiresFocus)="focusOnConversation()"
            [defaultSortOrder]="isLive ? 'newest' : 'likes' | as: 'any'"
            />
        }
      </mat-tab>
      @if (showChat && (useBantaChat || hasChat)) {
        <mat-tab label="Chat">
          <tyt-live-chat
            class="fullbleed"
            [stream]="media | as: 'any'"
            [platformChatEmbed]="platformChatEmbed"
          ></tyt-live-chat>
        </mat-tab>
      }
    </mat-tab-group>
  </aside>
</div>

<!-- MENUS -->
<mat-menu #overflowMenu>
  <mat-menu #devToolsMenu>
    <button mat-menu-item (click)="testCallToAction()">
      <mat-icon>flag</mat-icon>
      Test Call To Action
    </button>
    <button mat-menu-item (click)="disableAutoHide = !disableAutoHide">
      <mat-icon>{{ disableAutoHide ? 'toggle_off' : 'toggle_on' }}</mat-icon>
      Auto-hide Controls
    </button>
  </mat-menu>
  @if (session?.supportedPlaybackSpeeds) {
    <button mat-menu-item [matMenuTriggerFor]="playbackSpeedMenu">
      <mat-icon>speed</mat-icon>
      Playback Speed
    </button>
  }
  <button mat-menu-item (click)="toggleClosedCaption()">
    <mat-icon>closed_caption</mat-icon>
    @if (enabledClosedCaption) {
      Disable closed captioning
    }
    @if (!enabledClosedCaption) {
      Enable closed captioning
    }
  </button>
  @if (supportsPictureInPicture) {
    <button mat-menu-item (click)="enterPictureInPicture()">
      <mat-icon>picture_in_picture</mat-icon>
      Picture in Picture
    </button>
  }
  @if (session?.canBeWatchedExternally) {
    <button mat-menu-item (click)="watchExternally()">
      <mat-icon>open_in_new</mat-icon>
      {{session?.watchExternalLabel ?? defaultWatchExternallyLabel }}
    </button>
  }
  <button mat-menu-item (click)="disableAutoHide = !disableAutoHide">
    <mat-icon>{{ disableAutoHide ? 'toggle_off' : 'toggle_on' }}</mat-icon>
    Auto-hide Controls
  </button>
  @if (showDevTools) {
    <button mat-menu-item [matMenuTriggerFor]="devToolsMenu">
      <mat-icon>code</mat-icon>
      Dev Tools
    </button>
  }
  @for (item of additionalMenuItems; track item) {
    <button mat-menu-item (click)="item.handler()">
      <mat-icon>{{ item.icon }}</mat-icon>
      {{ item.label }}
    </button>
  }
</mat-menu>
<mat-menu #playbackSpeedMenu>
  @if (session?.supportedPlaybackSpeeds) {
    @for (speed of session.supportedPlaybackSpeeds; track speed) {
      <button
        (click)="setPlaybackRate(speed)"
        mat-menu-item>
        {{speed}}x
        @if (speed === 1) {
          (Normal)
        }
      </button>
    }
  }
</mat-menu>