import {
    Component, OnInit, Input, Output,
    ViewChild, QueryList, ElementRef, EventEmitter, inject
} from '@angular/core';
import { LoggerService } from '@tytapp/common';
import { Observable } from 'rxjs';

@Component({
    selector: 'player-volume-button',
    templateUrl: './player-volume-button.component.html',
    styleUrls: ['./player-volume-button.component.scss']
})
export class PlayerVolumeButtonComponent {
    private element = inject(ElementRef);
    private logger = inject(LoggerService);

    private documentMouseUp;
    private documentMouseMove;

    @Input()
    public volume: number;

    @Output()
    public change: EventEmitter<number> = new EventEmitter<number>();

    @ViewChild('volumeTrackReceiver', { static: true })
    private volumeTrackReceiver: ElementRef = null;

    private volumeStartDragClientY = 0;
    private volumeStartDragOffsetY = 0;
    private volumeDragPosition = 1;
    private volumeDragging = false;
    private openValue = false;
    private premuteVolume = 0.1;

    muted = false;

    @Input()
    get open(): boolean {
        return this.openValue;
    }

    sendChange() {
        this.change.next(this.muted ? 0 : this.volume);
    }

    toggleMute($event) {
        if (!this.muted) {
            // We are muting
            this.premuteVolume = this.volume;
        } else {
            this.volume = this.premuteVolume;
        }
        this.muted = !this.muted;
        this.sendChange();

        $event.stopPropagation();
        $event.preventDefault();
    }

    private isDescendant(parent: HTMLElement, child: HTMLElement) {
        var node = child.parentNode;
        while (node != null) {
            if (node == parent) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    }

    set open(value: boolean) {
        this.openValue = value;
        this.openChange.emit(this.openValue);

        if (typeof document !== 'undefined') {
            if (value) {
                this.closeHandler = event => {

                    if (this.element.nativeElement == event.target || this.isDescendant(this.element.nativeElement, event.target))
                        return;

                    document.removeEventListener('click', this.closeHandler);
                    this.open = false;
                };
                document.addEventListener('click', this.closeHandler);

            } else {
                document.removeEventListener('click', this.closeHandler);
            }
        }
    }

    private closeHandler;

    @Output()
    openChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('sliderButton')
    sliderButton: ElementRef<HTMLAnchorElement>;
    @ViewChild('volumeButton')
    volumeButton: ElementRef<HTMLAnchorElement>;

    openSlider() {
        this.open = true;

        // This is delayed as the volume slider now animates out, and if you
        // focus the button immediately the browser attempts to scroll the containers so that
        // it is visible, which slightly breaks the layout.

        setTimeout(() => {
            if (this.sliderButton) {
                this.sliderButton.nativeElement.focus();
            } else {
                this.logger.error(`Cannot find slider button!`);
            }
        }, 400);
    }

    get volumePercent() {
        return Math.round(this.volume * 100);
    }

    sliderButtonKey(ev: KeyboardEvent) {
        if (ev.code === 'ArrowUp' || ev.code === 'ArrowRight') {
            this.volume = Math.min(1, this.volume + 0.05);
            this.sendChange();
        } else if (ev.code === 'ArrowDown' || ev.code === 'ArrowLeft') {
            this.volume = Math.max(0, this.volume - 0.05);
            this.sendChange();
        } else if (ev.code === 'Escape') {
            this.closeSlider();
        } else {
            return;
        }

        ev.preventDefault();
        ev.stopPropagation();
    }

    closeSlider() {
        this.open = false;
        this.volumeButton.nativeElement.focus();
    }

    volumeSliderMouseDown(ev) {
        this.volumeDragging = true;
        this.volumeStartDragClientY = ev.clientY;
        this.volumeStartDragOffsetY = this.volume;

        let trackElement: HTMLElement = <HTMLElement>this.volumeTrackReceiver.nativeElement;

        if (ev.target == trackElement) {
            this.volumeStartDragOffsetY = 1 - (ev.offsetY / trackElement.offsetHeight);
        }

        if (typeof document !== 'undefined') {
            this.documentMouseUp = event => {
                document.removeEventListener('mouseup', this.documentMouseUp);
                document.removeEventListener('mousemove', this.documentMouseMove);

                this.volumeSliderMouseUp(event);
            };
            this.documentMouseMove = event => {
                this.volumeSliderMouseMove(event);
            };

            document.addEventListener('mouseup', this.documentMouseUp);
            document.addEventListener('mousemove', this.documentMouseMove);
        }

        this.volumeSliderMouseMove(ev);
    }

    private range(value, min, max) {
        if (value < min)
            return min;
        if (value > max)
            return max;

        return value;
    }

    volumeSliderMouseMove(ev) {
        if (!this.volumeDragging)
            return;

        let trackElement: HTMLElement = <HTMLElement>this.volumeTrackReceiver.nativeElement;
        let total = trackElement.offsetHeight;

        let travel = (ev.clientY - this.volumeStartDragClientY) / total;

        let x = this.volumeStartDragOffsetY - travel;
        let desiredSpot = Math.round(x * 1000) / 1000.0;

        this.volumeDragPosition = this.range(desiredSpot, 0, 1);
        this.volume = this.volumeDragPosition;
        this.muted = false;

        this.sendChange();

    }

    volumeSliderMouseUp(event) {
        this.volumeDragging = false;
        this.volume = this.volumeDragPosition;
        this.muted = false;
        this.sendChange();
    }

}
