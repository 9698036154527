import { ApiProduction, ApiVOD, ApiPodcast } from '@tytapp/api';
import { Download } from '../download';
import { DownloadManager } from '../download-manager';
import { v4 as uuid } from 'uuid';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { DownloadError, DownloadProgress, DownloadStartRequest, DownloadWorkerMessage } from './types';
import { downloadsDb, WebDownload } from './web-downloads.db';
import { isServerSide } from '@tytapp/environment-utils';
import { LoggerService } from '@tytapp/common';

class DownloadWorker {
    constructor(readonly download: Download) {
        this.worker = new Worker(new URL('./web-download.worker', import.meta.url));
        this.worker.addEventListener('message', ev => this.handleMessage(JSON.parse(ev.data)));
        this.send<DownloadStartRequest>({ type: 'start', download });
    }

    worker: Worker;

    private _finished = new ReplaySubject<void>();
    private _finished$ = this._finished.asObservable();
    get finished() { return this._finished$; }

    private _error = new ReplaySubject<DownloadError>();
    private _error$ = this._error.asObservable();
    get error() { return this._error$; }

    private _progress = new BehaviorSubject<number>(0);
    private _progress$ = this._progress.asObservable();
    get progress() { return this._progress$; }

    private _canceled = new ReplaySubject<void>();
    private _canceled$ = this._canceled.asObservable();
    get canceled() { return this._canceled$; }

    private send<T>(message: T) {
        this.worker.postMessage(JSON.stringify(message));
    }

    private handleMessage(message: DownloadWorkerMessage) {
        console.log(`[DownloadWorker/${this.download.id}] Window-side: Received message: `, message);
        if (message.type === 'finished')
            this._finished.next();
        else if (message.type === 'error')
            this._error.next(message as DownloadError);
        else if (message.type === 'progress')
            this._progress.next((message as DownloadProgress).progress);
        else if (message.type === 'canceled')
            this._canceled.next();
        else
            console.warn(`[DownloadWorker/${this.download.id}] Window-side: Received unknown message '${message.type ?? '<none>'}'`);
    }

    async cancel() {
        await new Promise<void>(resolve => {
            this.canceled.toPromise().then(() => resolve());
            this.worker.postMessage(JSON.stringify({ type: 'cancel' }));
        });

        this.worker.terminate();
    }

    terminate() {
        this.worker.terminate();
    }
}

export class WebDownloadManager implements DownloadManager {
    constructor(private readonly logger: LoggerService) {
    }

    private get $downloads() {
        return downloadsDb.table<WebDownload>('downloads');
    }

    readonly id = 'web';
    supportsWebPlayback = true;

    private downloadWorkers: DownloadWorker[] = [];

    private _downloadUpdated = new Subject<Download>();
    private _downloadUpdated$ = this._downloadUpdated.asObservable();
    get downloadUpdated() { return this._downloadUpdated$; }

    async list(): Promise<Download[]> {
        if (isServerSide())
            return [];

        return await this.$downloads.toArray()
            .then(list => list.map(x => x.download));
    }

    async isInterrupted(download: Download): Promise<boolean> {
        return !download.completed && !this.downloadWorkers.some(x => x.download.id === download.id);
    }

    async restart(download: Download): Promise<void> {
        if (isServerSide())
            throw new Error(`This operation is not valid in this context. [dl_restart]`);

        const existingWorker = this.downloadWorkers.find(x => x.download.id === download.id);
        if (existingWorker) {
            existingWorker.terminate();
        }

        download.status = 'downloading'
        download.progress = 0
        await this.$downloads.update(download.id, download);
        this._downloadUpdated.next(download);

        const worker = new DownloadWorker(download);
        this.downloadWorkers.push(worker);

        worker.canceled.subscribe(() => this.downloadWorkers = this.downloadWorkers.filter(x => x !== worker));
        worker.progress.subscribe(progress => {
            this.logger.info(`[WebDownloadManager] Received progress ${progress*100 | 0}% for download ${download.id}`);
            download.progress = progress;

            this._downloadUpdated.next(download);
        });
        worker.finished.subscribe(() => (download.completed = true, this._downloadUpdated.next(download)));
    }

    async start(production: ApiProduction, item: ApiVOD | ApiPodcast, asset: string): Promise<Download> {
        if (isServerSide())
            throw new Error(`This operation is not valid in this context. [dl_start]`);

        if (!asset)
            throw new Error(`Cannot download: No asset URL provided`);

        let id = uuid();
        let download = <Download>{
            id,
            production,
            cfid: item.id,
            asset_url: asset,
            progress: 0,
            completed: false,
            downloaded_at: new Date().getTime(),
            kind: item.type as any
        };

        await this.$downloads.add({
            id,
            download,
            blob: null,
            type: 'application/octet-stream'
        });

        await this.restart(download);
        return download;
    }

    async delete(download: Download): Promise<void> {
        if (isServerSide())
            throw new Error(`This operation is not valid in this context. [dl_delete]`);

        if (!download.completed) {
            const worker = this.downloadWorkers.find(x => x.download.id === download.id);
            if (worker) {
                worker.cancel();
            }
        }

        await this.$downloads.delete(download.id);
    }

    modeForDownload(download: Download) {
        if (download.kind === 'podcast')
            return 'podcasts';

        if (download.production.full_length_vods.some(x => x.id === download.cfid)) {
            return 'episodes';
        } else {
            return 'clips';
        }
    }

    itemForDownload(download: Download) {
        if (download.kind === 'podcast')
            return download.production.full_length_podcasts.find(x => x.id === download.cfid);

        if (download.production.full_length_vods.some(x => x.id === download.cfid)) {
            return download.production.full_length_vods.find(x => x.id === download.cfid);
        } else {
            return download.production.vod_clips.find(x => x.id === download.cfid);
        }
    }

    async play(download: Download): Promise<void> {
        throw new Error('Not implemented'); // See DownloadsService instead
    }

    async getUrlForDownload(download: Download): Promise<[ string, string ]> {
        if (isServerSide())
            throw new Error(`This operation is not valid in this context. [dl_url]`);

        let webDownload = await this.$downloads.get(download.id);
        if (!webDownload)
            return undefined;
        return [ webDownload.type, URL.createObjectURL(webDownload.blob) ];
    }
}