import { Component, OnInit } from '@angular/core';
import { PlaybackSession } from '../media-services';
import { Playback } from '../playback';
import { SubscriptionSet } from '@tytapp/common';
import { SubSink } from 'subsink';

@Component({
    selector: 'tyt-remote-audio-player',
    templateUrl: './remote-audio-player.component.html',
    styleUrls: ['./remote-audio-player.component.scss']
})
export class RemoteAudioPlayerComponent implements OnInit {

    constructor(
        private playback: Playback
    ) {
    }

    private subsink = new SubSink();

    ngOnInit() {
        this.subsink.add(this.playback.sessionChanged.subscribe(session => this.connectToSession(session)));
    }

    ngOnDestroy() {
        this.subsink.unsubscribe();
        if (this._sessionSubscriptions) {
            this._sessionSubscriptions.unsubscribeAll();
            this._sessionSubscriptions = null;
        }
    }

    private connectToSession(session: PlaybackSession) {
        if (this._session)
            this.disconnectFromSession(this._session);

        if (!session)
            return;

        this._session = session;
        this._sessionSubscriptions = new SubscriptionSet();
        this._sessionSubscriptions.subscribe(this._session.positionChanged, position => this.seekPosition = position);
        this._sessionSubscriptions.subscribe(this._session.playStateChanged, state => {
            if (state == 'paused')
                this.playing = false;
            else
                this.playing = true;
        });
    }

    get volume(): number {
        if (!this._session)
            return 1;

        if (this._session)
            return this._session.volume;
    }

    playNextItem() {
        if (!this.playback.currentPlayer)
            return;

        this.playback.currentPlayer.nextItem();
    }

    playPreviousItem() {
        if (!this.playback.currentPlayer)
            return;

        this.playback.currentPlayer.previousItem();

    }

    setVolume(value: number) {
        if (!this._session)
            return;

        this._session.setVolume(value);
    }

    resume() {
        if (!this._session)
            return;
        this._session.resume();
    }

    seekBack() {
        if (!this._session)
            return;

        this._session.seek(Math.max(0, this._session.position - 15));
    }

    seek(positionFactor: number) {
        if (!this._session)
            return;

        let position = positionFactor * this._session.length;
        this._session.seek(position);
    }

    seekForward() {
        if (!this._session)
            return;

        if (this._session.position + 15 > this._session.length) {
            this._session.seek(this._session.length - 1);
            return;
        }

        this._session.seek(this._session.position + 15);
    }

    pause() {
        if (!this._session)
            return;
        this._session.pause();
    }

    private disconnectFromSession(session: PlaybackSession) {
        if (this._sessionSubscriptions)
            this._sessionSubscriptions.unsubscribeAll();
        this._session = null;
        this._sessionSubscriptions = null;
    }

    seekPosition: number = 0;
    playing: boolean = false;

    get seekPercentage(): number {
        if (!this._session || !this._session.length || !this.seekPosition)
            return 0;

        return this.seekPosition / this._session.length;
    }

    private _session: PlaybackSession;
    private _sessionSubscriptions: SubscriptionSet;
}
