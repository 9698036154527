import { ContentBlockType } from '@tytapp/content';
import { VideoBlock } from './video-block';

export * from './video-block';

export const VIDEO_BLOCK_TYPE: ContentBlockType<VideoBlock> = {
    id: 'video',
    label: 'Video (TYT.com)',
    icon: 'play_arrow',
    template: {
        productionID: '',
        vodID: ''
    },
    preview: {
        productionID: 'preview',
        vodID: 'preview'
    },
    previewSettings: {
        verticalAlign: 'center'
    },
    deferredEditorComponent: () => import('./video-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./video-block.view').then(m => m.ViewComponent)
}