import { inject } from '@angular/core';
import { Injectable } from '@banta/common';
import { ApiPodcast, ApiProduction, ApiVOD } from '@tytapp/api';
import { LoggerService, Shell, deepCopy } from '@tytapp/common';
import { NativeDownloadManager } from './native-downloads';
import { HostApi } from '@tytapp/common';
import { Playback } from './playback';
import { Subject, Subscription } from 'rxjs';
import { Download } from './download';
import { DownloadManager } from './download-manager';
import { WebDownloadManager } from './web-downloads';
import { isServerSide } from '@tytapp/environment-utils';

@Injectable()
export class DownloadsService {
    private manager: DownloadManager;
    private shell = inject(Shell);
    private hostApi = inject(HostApi);
    private playback = inject(Playback);
    private logger = inject(LoggerService);

    constructor() {
        this.init();
    }

    private markReady: () => void;
    private ready = new Promise<void>(r => this.markReady = r);

    private async init() {
        if (await this.hostApi.hasCapability('downloads:vod'))
            this.setDownloadManager(new NativeDownloadManager(this.hostApi, this.logger));
        else
            this.setDownloadManager(new WebDownloadManager(this.logger));

        this.markReady();
    }

    get enabled() {
        return !isServerSide() && this.shell.hasFeatureSync('apps.web.enable_downloads');
    }

    private _downloadUpdated = new Subject<Download>();
    private _downloadUpdated$ = this._downloadUpdated.asObservable();
    get downloadUpdated() { return this._downloadUpdated$; }

    private managerSubscriptions = new Subscription();

    get downloadManager() {
        return this.manager;
    }

    setDownloadManager(manager: DownloadManager) {
        this.managerSubscriptions.unsubscribe();
        this.managerSubscriptions = new Subscription();
        this.managerSubscriptions.add(manager.downloadUpdated.subscribe(async download => {
            this.logger.info(`[DownloadsService] Download ${download.id} was updated.`);
            await this.assessStatus(download);
            this._downloadUpdated.next(download);
        }));
        this.manager = manager;
    }

    async list(): Promise<Download[]> {
        await this.ready;
        let downloads = await this.manager.list();

        for (let download of downloads) {
            this.assessStatus(download);
        }

        return downloads;
    }

    private async assessStatus(download: Download) {
        await this.ready;
        if (download.completed) {
            download.status = 'completed';
        } else if (await this.isInterrupted(download)) {
            download.status = 'interrupted';
        } else {
            download.status = 'downloading';
        }
    }

    async restart(download: Download) {
        await this.ready;
        return this.manager.restart(download);
    }

    async start(production: ApiProduction, item: ApiVOD | ApiPodcast, asset: string) {

        if (!asset)
            throw new Error(`Cannot download: No asset URL provided`);

        production = JSON.parse(JSON.stringify(production));
        production.full_length_podcasts = production.full_length_podcasts?.filter(x => x.id === item.id);
        production.full_length_vods = production.full_length_vods?.filter(x => x.id === item.id);
        production.vod_clips = production.vod_clips?.filter(x => x.id === item.id);
        production.audio_clips = production.audio_clips?.filter(x => x.id === item.id);

        await this.ready;
        await this.manager.start(production, item, asset);
    }

    /**
     * @param download
     */
    async isInterrupted(download: Download) {
        await this.ready;
        return await this.manager.isInterrupted(download);
    }

    async delete(download: Download) {
        await this.ready;
        await this.manager.delete(download);
    }

    async play(download: Download) {
        await this.ready;

        if (this.manager.supportsWebPlayback) {
            let [ type, url ] = await this.manager.getUrlForDownload(download);
            if (!url)
                throw new Error(`Cannot play: Could not locate downloaded file.`);

            const item = deepCopy(this.itemForDownload(download));
            item.asset = { provider: 'downloads', url };

            this.playback.transferPlaybackToApp(
                undefined,
                download.production,
                this.modeForDownload(download),
                item,
                0, false
            );
        } else {
            return this.manager.play(download);
        }
    }

    modeForDownload(download: Download) {
        if (download.kind === 'podcast')
            return 'podcasts';

        if (download.production.full_length_vods.some(x => x.id === download.cfid)) {
            return 'episodes';
        } else {
            return 'clips';
        }
    }

    async getDownloadForItem(item: ApiVOD | ApiPodcast, asset: string) {
        return (await this.list()).find(x => x.cfid === item.id && x.asset_url === asset);
    }

    itemForDownload(download: Download) {
        if (download.kind === 'podcast')
            return download.production.full_length_podcasts.find(x => x.id === download.cfid);

        if (download.production.full_length_vods.some(x => x.id === download.cfid)) {
            return download.production.full_length_vods.find(x => x.id === download.cfid);
        } else {
            return download.production.vod_clips.find(x => x.id === download.cfid);
        }
    }

}