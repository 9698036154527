import { Component, inject } from '@angular/core';
import { GoogleCastService } from '@tytapp/media-playback/google-cast.service';

@Component({
    selector: 'tyt-cast-sender',
    template: ''
})
export class CastSenderComponent {
    googleCast = inject(GoogleCastService);

    ngOnInit() {
        this.googleCast.initialize();
    }
}