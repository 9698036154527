import { Injectable, inject } from '@angular/core';
import { isServerSide } from '@tytapp/environment-utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { Playback } from './playback';
import { LoggerService } from '@tytapp/common';

declare global {
    interface Window { WebKitPlaybackTargetAvailabilityEvent: any; }
    interface HTMLMediaElement { webkitShowPlaybackTargetPicker: () => void; }
    interface MediaController {
        pause: () => void;
        play: () => void;
        unpause: () => void;
    }
}

@Injectable()
export class AirPlayService {
    private playback = inject(Playback);
    private logger = inject(LoggerService);

    private _connectedChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _availableChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _connected: boolean;

    playlist: any[];

    async initialize() {
        if (isServerSide() || !window.WebKitPlaybackTargetAvailabilityEvent) {
            this._availableChanged.next(false);
            return;
        }

        this.playback.playerChanged.subscribe(player => {
            if (player) {
                player.currentSessionChanged.subscribe(session => {
                    if (!session)
                        return;

                    this.attachToMediaElement(session.mediaElement);
                });
            }
        });

    }

    public element: HTMLMediaElement;

    public get isAudio() {
        return this.element instanceof HTMLAudioElement;
    }

    isFakeElement: boolean = false;

    private attachToMediaElement(element: HTMLMediaElement) {
        this.element = element;
        this.isFakeElement = false;

        if (!this.element) {
            // We'll make our own, and when we transition into airplay, we'll destroy our fake media player.
            element = document.createElement('video');
            this.element = element;
            this.isFakeElement = true;
            element.src = "https://s3.amazonaws.com/tyt2-cdn-production/production/media/flag1.mp4";
        }

        let updateTimeout;
        let lastStateReported: boolean = undefined;

        element.addEventListener('webkitplaybacktargetavailabilitychanged', (event: any) => {
            let available = event.availability == 'available';
            this.logger.info(`AirPlay: Available=${available}`);
            this._availableChanged.next(available);
        });

        let checkConnected = () => {

            this._connected = element && element['webkitCurrentPlaybackTargetIsWireless'];
            this.logger.info(`AirPlay: Active=${this.connected}`);

            if (this.connected) {
                this.playback.lockControlsVisible = true;

                this.element.play();

                if (this.isFakeElement) {
                    this.playback.globalBuffering = true;
                    setTimeout(async () => {
                        this.element.remove();
                        this.element.src = undefined;
                        element = this.element = null;

                        if (this.playback.currentPlayer) {
                            try {
                                await this.playback.currentPlayer.refreshMediaResolution(true);
                            } finally {
                                this.playback.globalBuffering = false;
                            }
                        } else {
                            this.playback.globalBuffering = false;
                        }
                    }, 6000);
                } else {
                }
            } else {
                this.playback.lockControlsVisible = false;
            }

            this._connectedChanged.next(this.connected);
        };

        element.addEventListener('webkitcurrentplaybacktargetiswirelesschanged', checkConnected);
        checkConnected();
    }

    get connected() {
        return this._connected;
    }

    pickRoute() {
        this.logger.info(`AirPlay: Showing route picker...`);
        if (!this.element)
            return;

        this.element.webkitShowPlaybackTargetPicker();
    }

    get connectedChanged(): Observable<boolean> {
        return this._connectedChanged;
    }

    get availableChanged(): Observable<boolean> {
        return this._availableChanged;
    }
}