import { isPlatformServer } from "@angular/common";
import { Component, inject, Input, Output, PLATFORM_ID, QueryList, ViewChild } from "@angular/core";
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from "@angular/router";
import { ApiPodcast, ApiProduction, ApiShow, ApiTuneInProvider, ApiUser, ApiVOD } from "@tytapp/api";
import { BillingService } from '@tytapp/billing';
import { sleep } from "@tytapp/common";
import { ProductionsService } from '@tytapp/common-ui';
import { isServerSide } from '@tytapp/environment-utils';
import { Download } from '../download';
import { DownloadsService } from '../downloads.service';
import { Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { PlaybackSession } from "../media-services";
import { MEDIA_TYPE_HELPER, Playlist } from '../media-types';
import { NoJsMediaPlayerComponent } from "../no-js-media-player/no-js-media-player.component";
import { PlayerComponent } from "../player/player.component";

interface DownloadType {
    type: string;
    label: string;
    download: Download;
    asset: string;
}

@Component({
    selector: 'tyt-media-view',
    templateUrl: './media-view.component.html',
    styleUrls: ['./media-view.component.scss']
})
export class MediaViewComponent {
    private readonly platformId = inject(PLATFORM_ID);
    private readonly route = inject(ActivatedRoute);
    private readonly billing = inject(BillingService);
    private readonly downloads = inject(DownloadsService);
    private readonly matSnackBar = inject(MatSnackBar);
    private readonly router = inject(Router);
    private readonly productions = inject(ProductionsService);

    readonly MediaTypes = MEDIA_TYPE_HELPER;

    ngOnInit() {
        this.subscriptions.add(this.billing.entitlementChanged.subscribe(entitled => this.entitled = entitled));
    }

    paywallVisible = false;
    enableNoJsMediaPlayer = false;

    get downloadsEnabled() {
        return this.downloads.enabled;
    }

    get transcript() {
        //return `[00:00:01]\n\nHello world\nHow are\nYou\n\n[00:00:03]\n\nMore stuff\nHere`;
        return this.item.asset?.transcript;
    }

    async getDownload(item: ApiVOD | ApiPodcast, asset: string) {
        return await this.downloads.getDownloadForItem(item, asset);
    }

    async startDownload(item: ApiVOD | ApiPodcast, asset: string) {
        if (!asset) {
            alert(`Cannot download: No asset URL provided`);
            return;
        }

        let download = await this.downloads.start(this.production, item, asset);
        let snack = this.matSnackBar.open('Download started', 'View', { duration: 5000 });

        await this.reloadDownloads();

        snack.onAction().subscribe(() => this.router.navigateByUrl('/downloads'));
    }

    private subscriptions = new Subscription();
    private viewReady = new Promise<void>((res, rej) => this.fireViewReady = res);
    private fireViewReady: Function;

    _production: ApiProduction;
    _item: ApiVOD | ApiPodcast;

    entitled = false;

    @Input() enableEditor: boolean = true;
    @Input() autoplay = false;
    @Input() show: ApiShow;
    @Input() get production() { return this._production; }
    @Input() get item() { return this._item; }
    @Input() inEditor = false;
    @Input() mode: 'episodes' | 'clips' | 'podcasts' | 'audio-stories' = 'episodes';
    @Input() user: ApiUser;
    @Input() showComments = true;
    @Input() embed = false;
    @Input() showDescription = true;
    @Input() showPlaylist = true;
    @Input() showSocialButtons = true;

    @Output() playbackRevoked = new Subject<void>();
    @Output() itemChange = new Subject<ApiVOD | ApiPodcast>();
    @Output() modeChange = new Subject<Playlist>();

    @ViewChild('player', { static: true }) player: PlayerComponent;
    @ViewChild('player', { static: true }) playerQuery: QueryList<PlayerComponent>;
    @ViewChild(NoJsMediaPlayerComponent, { static: true }) nativeVideoPlayerComponent: NoJsMediaPlayerComponent;

    get showEditLink() { return this.enableEditor && this.user?.staff; }
    get currentlyPlayingVOD() { return this.item as ApiVOD; }
    get currentlyPlayingPodcast() { return this.item as ApiPodcast; }
    set production(value) { this._production = value; }

    set item(value) {
        if (this._item === value)
            return;
        this._item = value;
        setTimeout(() => this.load());
    }

    updateItem(item: ApiVOD | ApiPodcast) {
        this._item = item;
        this.itemChange.next(item);
    }

    async load() {
        this.checkSSRPlayer();

        const filteredPlaylistItem = this.playlist.find(podcast => podcast.id === this.route.snapshot.params.vod);

        let vod = this._item || filteredPlaylistItem;

        if (isPlatformServer(this.platformId) && this.nativeVideoPlayerComponent) {
            this.nativeVideoPlayerComponent.play(vod.asset);
        } else {
            await this.viewReady;
            await this.playItem(vod, this.mode);
            //this.airplayService.loadMedia(vod);
        }

        // Downloads

        this.reloadDownloads();
    }

    private downloadsSubscription = new Subscription();
    private downloadsList: Download[] = [];
    private vodItem: ApiVOD;

    async reloadDownloads() {
        if (!this.downloadsEnabled) {
            this.downloadsList = await this.downloads.list();
            this.subscriptions.remove(this.downloadsSubscription);
            this.downloadsSubscription.unsubscribe();
            this.downloadsSubscription = new Subscription();
            this.subscriptions.add(this.downloadsSubscription);
            this.downloadsSubscription.add(
                this.downloads.downloadUpdated.subscribe(download => this.updateDownload(download))
            );
        }

        if (this.item.type === 'vod') {
            this.downloadTypes = [
                { type: 'hd', label: 'HD', download: this.hdDownload, asset: this.item?.asset?.hd_video_download_url },
                { type: 'sd', label: 'SD', download: this.sdDownload, asset: this.item?.asset?.sd_video_download_url },
                { type: 'audio', label: 'Audio', download: this.audioDownload, asset: this.item?.asset?.audio_download_url },
            ];
        } else {
            this.downloadTypes = [
                { type: 'audio', label: 'Audio', download: this.audioDownload, asset: this.item?.asset?.url },
            ];
        }

        if (this.downloadsEnabled) {
            for (let download of await this.downloads.list())
                this.updateDownload(download);
        }
    }

    private updateDownload(download: Download) {
        let existingDownload = this.downloadsList.find(x => x.cfid === download.cfid && x.asset_url === download.asset_url);
        if (existingDownload) {
            Object.assign(existingDownload, download, { production: existingDownload.production });
        } else {
            this.downloadsList.push(download);
        }

        download = existingDownload ?? download;

        if (download.cfid === this.item?.id) {
            for (let downloadType of this.downloadTypes) {
                if (downloadType.asset === download.asset_url) {
                    downloadType.download = download
                }
            }
        }
    }

    downloadTypeId(downloadType: { type: string }) {
        return downloadType.type;
    }

    hdDownload: Download;
    sdDownload: Download;
    audioDownload: Download;

    ngAfterViewInit() {
        this.fireViewReady();
    }

    downloadTypes: DownloadType[] = [];

    progressFor(download: Download) {
        if (!download.progress)
            return 0;

        return Math.round(download.progress * 100);
    }

    get playlist(): ApiVOD[] | ApiPodcast[] {
        if (!this.production)
            return undefined;

        if (this.mode === 'episodes')
            return this.production.full_length_vods;
        else if (this.mode === 'clips')
            return this.production.vod_clips;
        else if (this.mode === 'podcasts')
            return this.production.full_length_podcasts;
        else if (this.mode === 'audio-stories')
            return this.production.audio_clips;
        else
            throw new Error(`No valid playlist for mode '${this.mode}'`);
    }

    async waitForPlayer() {
        if (this.player)
            return this.player;

        await this.playerQuery.changes.pipe(take(1)).toPromise();
        return this.playerQuery.first;
    }

    get topicIdentifier() {
        if (!this.production || !this.item)
            return null;

        if (this.mode === 'podcasts')
            return `podcast_${this.item.id}`;
        else
            return `vod_${this.item.id}`;
    }

    get topicTitle() {
        if (!this.production || !this.item)
            return null;

        return this.item.description;
    }

    get loginOrJoinUrl() {
        if (!this.user)
            return `/login`;
        return `/join`;
    }

    get modeLabel() {
        let labels = {
            episodes: 'Episode',
            clips: 'Clips',
            podcasts: 'Podcasts'
        }

        return labels[this.mode];
    }

    selectedModeTab = 0;

    async playItem(vod: ApiVOD | ApiPodcast, mode: 'episodes' | 'podcasts' | 'clips' | 'audio-stories'): Promise<PlaybackSession> {
        if (isServerSide())
            return;

        this._item = vod;
        this.reloadDownloads();

        if (mode !== this.mode) {
            this.mode = mode;
            this.modeChange.next(mode);
            this.selectedModeTab = 0;
        }

        await sleep(1);
        await this.player.playContent(this.production, this.mode, vod);
    }

    async checkSSRPlayer() {
        if (this.nativeVideoPlayerComponent)
            await this.nativeVideoPlayerComponent.viewReady;
    }

    getPodcastProviders(show: ApiShow): ApiTuneInProvider[] {
        if (!show || !show.tune_ins)
            return [];

        let podcastTuneIn = show.tune_ins.find(x => x.type == 'podcast');

        if (!podcastTuneIn)
            return [];

        return podcastTuneIn.destinations;
    }

    get canonicalUrl() {
        if (!this.production || !this.item)
            return undefined;

        return this.productions.getWatchUrlSync(this.production, this.mode, this.item, true);
    }
}