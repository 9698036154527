import { Component, HostBinding, Input, Output } from '@angular/core';
import { environment } from '@tytapp/environment';
import { Subject } from 'rxjs';

@Component({
    selector: 'tyt-yt-chat-native-message',
    templateUrl: './yt-chat-native-message.component.html',
    styleUrls: ['./yt-chat-native-message.component.scss']
})
export class YtChatNativeMessageComponent {

    hidden = false;

    @Output() watchExternally = new Subject<void>();
    @Output() watchOnWeb = new Subject<void>();
    @Input() watchOnWebUrl: string;

    get isNativeBuild() {
        return environment.isNativeBuild;
    }

    @HostBinding('class.visible')
    get isVisible() {
        return this.isNativeBuild && !this.hidden;
    }
}