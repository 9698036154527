import {
    Component, OnInit, OnDestroy, ViewChildren, QueryList,
    ElementRef, AfterViewInit, Input, Output, EventEmitter,
    ViewChild
} from '@angular/core';
import { DurationPipe } from '@tytapp/common/duration.pipe';
import { Subject } from 'rxjs';

@Component({
    selector: 'player-seek-bar',
    templateUrl: './player-seek-bar.component.html',
    styleUrls: ['./player-seek-bar.component.scss']
})
export class PlayerSeekBarComponent implements OnInit, OnDestroy, AfterViewInit {

    constructor() { }

    durationPipe = new DurationPipe();

    ngOnInit() {
    }

    ngOnDestroy() {
    }

    ngAfterViewInit() {
    }

    private _seekPosition: number;

    a11ySeekValue: number = 0;

    @Input()
    get seekPosition() { return this._seekPosition; }

    set seekPosition(value) {
        this._seekPosition = value;
        if (!this.hasFocus) {
            setTimeout(() => this.updateA11yMetadata());
        }
    }

    private updateA11yMetadata() {
        this.a11ySeekValue = this.seekPosition * this.duration;
        this.seekText =
            `${this.durationPipe.transform(this.seekPosition * this.duration, 'seconds')}`
            + ` of ${this.durationPipe.transform(this.duration, 'seconds')}`
        ;
    }

    setIsFocused(value: boolean) {
        this.hasFocus = value;
    }

    hasFocus = false;

    seekText: string = '';

    @Input()
    public duration: number;

    @Input()
    public isLive: boolean;

    @Input()
    public seekPreviewValue: number;

    @Output()
    public seek: EventEmitter<number> = new EventEmitter<number>();

    @Output()
    public seekDrag: Subject<number> = new Subject<number>();

    @ViewChild('seekTrackReceiver', { static: true })
    private seekTrackReceiver: ElementRef = null;

    public dragging = false;

    public seekDragPosition: number;
    private seekStartDragClientX: number;
    private seekStartDragOffsetX: number;

    public get displaySeekPosition() {
        return Math.max(0, Math.min(1, this.seekPosition));
    }

    handleKey(event: KeyboardEvent) {
        let distance = 10

        if (event.code === 'ArrowLeft') {
            this.seekPosition -= distance / this.duration;
        } else if (event.code === 'ArrowRight') {
            this.seekPosition += distance / this.duration;
        } else {
            return;
        }

        this.seekPosition = Math.max(0, Math.min(1, this.seekPosition));
        this.seek.next(this.seekPosition);
        this.updateA11yMetadata();

        event.preventDefault();
        event.stopPropagation();
    }

    private range(value, min, max) {
        if (value < min)
            return min;
        if (value > max)
            return max;

        return value;
    }

    seekPointerUp(ev: PointerEvent) {
        if (ev.pointerId !== this.seekPointerId)
            return;

        this.dragging = false;

        this.seekPointerId = undefined;
        this.seekPosition = this.seekDragPosition;
        this.seek.emit(this.seekPosition);
        this.updateA11yMetadata();
    }

    private seekPointerId: number;
    seekPointerDown(ev: PointerEvent) {
        if (this.dragging)
            return;

        this.dragging = true;

        ev.preventDefault();

        (ev.target as HTMLElement).setPointerCapture(ev.pointerId);

        this.seekPointerId = ev.pointerId;
        this.seekStartDragClientX = ev.clientX;
        this.seekStartDragOffsetX = this.seekPosition;

        let seekTrackReceiverEl = <HTMLElement>this.seekTrackReceiver.nativeElement;
        if (ev.target == seekTrackReceiverEl)
            this.seekStartDragOffsetX = ev.offsetX / seekTrackReceiverEl.offsetWidth;

        this.seekPointerMove(ev);
        this.seekPosition = this.seekDragPosition;
        this.seek.emit(this.seekPosition);
    }

    seekPointerMove(ev: PointerEvent) {
        if (!this.dragging || ev.pointerId !== this.seekPointerId)
            return;

        let seekTrackReceiverEl = <HTMLElement>this.seekTrackReceiver.nativeElement;
        let total = seekTrackReceiverEl.offsetWidth;

        let travel = (ev.clientX - this.seekStartDragClientX) / total;
        let x = this.seekStartDragOffsetX + travel;
        let desiredSpot = Math.round(x * 1000) / 1000.0;

        // TODO: seek
        this.seekDragPosition = this.range(desiredSpot, 0, 1);
        this.seekDrag.next(this.seekDragPosition);
    }

}
