import { Download } from '../download';
import Dexie from 'dexie';

export interface WebDownload {
    id: string;
    download: Download;
    blob: Blob;
    type: string;
}

export const downloadsDb = new Dexie('downloads');
downloadsDb.version(1).stores({
    downloads: 'id'
});