import { Component, OnInit, Input, ViewChild, OnDestroy, ElementRef, HostBinding, Output, inject } from '@angular/core';
import { ApiProduction, ApiVOD, ApiPodcast } from '@tytapp/api';
import { PlayerComponent } from '../player/player.component';
import { UserService } from '@tytapp/user';
import { Shell, SubscriptionSet } from '@tytapp/common';
import { Subject } from 'rxjs';
import { ApiUser } from '@tytapp/api';
import { Router } from '@angular/router';
import { isClientSide } from '@tytapp/environment-utils';
import { BillingService } from '@tytapp/billing';
import { ProductionsService } from '@tytapp/common-ui';

@Component({
    selector: 'tyt-production-view',
    templateUrl: './production-view.component.html',
    styleUrls: ['./production-view.component.scss']
})
export class ProductionViewComponent implements OnInit, OnDestroy {

    private userService = inject(UserService);
    private billing = inject(BillingService);
    private elementRef: ElementRef<HTMLElement> = inject(ElementRef);
    private router = inject(Router);
    private shell = inject(Shell);
    private productionsService = inject(ProductionsService);

    @ViewChild('player')
    public player: PlayerComponent;

    @Input()
    public production: ApiProduction;

    @Input() showCommentsCount = true;

    @Input()
    public includeMetadata = true;

    @Input()
    public includeItems = true;

    @Input()
    public showOnlyItem: ApiVOD | ApiPodcast;

    public get isStaff() {
        return this.user && this.user.staff;
    }

    @Input()
    public mode: "clips" | "episodes" | "podcasts" | "audio-stories" = 'episodes';

    @Input()
    public includeShowName: boolean = false;

    @Input()
    public includeTalent: boolean = true;

    @Input()
    public limit = null;

    @Input()
    public playInline = false;

    @Input()
    public playRemote = false;

    @Input()
    public showImage = true;

    /**
     * Default is context sensitive.
     */
    @Input()
    public showDate: boolean = undefined;

    @Input()
    public showFeedTag = true;

    @Output()
    public playClicked = new Subject<ApiVOD | ApiPodcast>();

    @Input()
    public confirmationPage: boolean = false;

    @HostBinding('class.has-paywall')
    paywallVisible: boolean = false;

    user: ApiUser = null;
    sessions = {};
    audienceType: string = 'visitor';

    get firstItem() {
        if (this.items && this.items.length > 0 && this.production && this.production.show) {
            return this.items[0];
        }

        return undefined;
    }

    get itemLabel() {
        if (this.mode === 'podcasts')
            return 'podcast';
        if (this.mode === 'episodes')
            return 'episode';
        if (this.mode === 'clips')
            return 'clip';
        return 'video';
    }

    get linkToWatchFirstItem() {
        if (this.firstItem)
            return this.productionsService.getWatchUrlSync(this.production, this.mode, this.firstItem);
        else
            return undefined;
    }

    playButtonClicked(event: MouseEvent, item: ApiVOD | ApiPodcast) {
        event.preventDefault();
        event.stopPropagation();

        if (this.isPlaying(item))
            return;

        if (this.playInline) {
            this.playItem(item);
        } else {
            let anchor = event.currentTarget as HTMLAnchorElement;
            if (anchor.href) {
                this.router.navigateByUrl(new URL(anchor.href).pathname);
            }
        }
    }

    async playItem(vodOrPodcast: ApiVOD | ApiPodcast) {

        if (this.playRemote) {
            this.playClicked.next(vodOrPodcast);
            return;
        }

        if (isClientSide()) {
            let element: HTMLElement = this.elementRef.nativeElement;

            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            }
        }

        if (this.mode === 'podcasts' && vodOrPodcast.is_premium && (!await this.billing.isEntitled()))
            this.showImage = true;

        await this.player.playContent(this.production, this.mode, vodOrPodcast);
    }

    get playlist(): ApiVOD[] | ApiPodcast[] {
        if (!this.production)
            return undefined;

        if (this.mode === 'episodes')
            return this.production.full_length_vods;
        else if (this.mode === 'clips')
            return this.production.vod_clips;
        else if (this.mode === 'podcasts')
            return this.production.full_length_podcasts;
        else if (this.mode === 'audio-stories')
            return this.production.audio_clips;
        else
            throw new Error(`No valid playlist for mode '${this.mode}'`);
    }

    @Input()
    currentlyPlayingItem: ApiVOD | ApiPodcast = null;


    get currentlyPlayingVOD(): (ApiVOD | ApiPodcast) {
        return this.currentlyPlayingItem;
    }

    get currentlyPlayingPodcast(): ApiPodcast {
        return this.currentlyPlayingItem;
    }

    onPlaybackRevocation() {

    }

    isPlaying(item: ApiVOD | ApiPodcast) {

        if (!this.currentlyPlayingItem || !item)
            return false;

        if (this.currentlyPlayingItem.id == item.id)
            return true;

        return false;
    }

    get date() {
        if (!this.production)
            return '';

        return this.production.date_simple;
    }

    get items() {
        let items = [];
        if (this.mode == 'clips') {
            items = <any>this.production.vod_clips;
        } else if (this.mode == 'episodes') {
            items = <any>this.production.full_length_vods;
        } else if (this.mode == 'podcasts') {
            items = <any>this.production.full_length_podcasts;
        }

        if (this.limit > 0) {
            items = items.slice(0, this.limit);
        }
        return items;
    }

    subscriptions: SubscriptionSet = new SubscriptionSet();

    async ngOnInit() {
        this.subscriptions.subscribe(this.userService.userChanged, user => this.user = user);
        this.audienceType = this.userService.audienceType;
        this.isOpenBeta = await this.shell.hasFeature('apps.web.nation_open_beta');
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribeAll();
    }
    isOpenBeta: boolean;
    get shouldShowTalentUser() {
        if(!this.user) return false;
        if (this.isOpenBeta || (!this.isOpenBeta && this.user.can_access_tytnation)) {
            return true;
        }
        return false;
    }

    watchUrl(item: any) {
        return this.productionsService.getWatchUrlSync(this.production, this.mode, item);
    }
}
