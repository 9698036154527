import { Component, Input, Output, inject } from '@angular/core';
import { AnalyticsService } from '@tytapp/analytics';
import { ApiLiveStream } from '@tytapp/api';
import { Shell } from '@tytapp/common';
import { environment } from '@tytapp/environment';
import { UserService } from '@tytapp/user';
import { Subject } from 'rxjs';

@Component({
    selector: 'tyt-live-chat',
    templateUrl: './live-chat.component.html',
    styleUrls: ['./live-chat.component.scss']
})
export class LiveChatComponent {
    private shell = inject(Shell);
    private analytics = inject(AnalyticsService);

    @Input() platformChatEmbed: any;
    @Input() stream: ApiLiveStream;
    @Output() watchExternally = new Subject<void>();

    useBantaChat = false;

    async ngOnInit() {
        this.useBantaChat = await this.shell.hasFeature('apps.web.use_banta_chat');
    }

    get watchOnWebUrl() {
        return `${environment.urls.webRoot}/live/streams/${this.stream.id}`;
    }

    recordWatchOnWebBeacons() {
        this.analytics.sendBeacons(`Clicked 'Watch on TYT.com' (from YouTube chat section)`, () => {
            this.analytics.sendBeaconGA4('watch_on_web', {
                label: 'Watch on TYT.com'
            });
        });
    }
}