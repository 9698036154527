import { Component, HostBinding, inject } from '@angular/core';
import { PageComponent } from '@tytapp/common';
import { GoogleCastService } from '../google-cast.service';

@Component({
    selector: 'tyt-cast-button',
    templateUrl: './cast-button.component.html',
    styleUrls: ['./cast-button.component.scss']
})
export class CastButtonComponent extends PageComponent {
    private googleCastService = inject(GoogleCastService);

    @HostBinding('class.hidden')
    get isHidden() {
        // The modern guidelines recommend keeping the Cast icon visible at all times in case users are off their
        // main network. It also allows users to use the Pairing Code / Audio Pairing features of modern Chromecast
        // devices. To allow for this, we will always show the cast icon.
        // However, if Google Cast is not available on the browser (or mobile OS) that we are running in,
        // we should hide the cast button (ie TYT.com on iOS Safari).

        return !this.googleCastService.supported; //!this.available && !this.connected;
    }

    available: boolean = false;
    connected: boolean = false;
    connecting: boolean = false;

    async init() {
        let self = this;

        this.subscribe(this.googleCastService.availableChanged, state => this.available = state);
        this.subscribe(this.googleCastService.connectedChanged, state => self.connected = state);
        this.subscribe(this.googleCastService.connectingChanged, state => self.connecting = state);
    }

    async startCast() {
        this.googleCastService.castNow();
    }

    async stopCast() {
        this.googleCastService.stopCasting();
    }

}
