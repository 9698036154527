import { BehaviorSubject, Observable, Subject } from 'rxjs';

export type CastSessionState = 'resuming' | 'resumed' | 'resumeFailed' | 'ended' | 'ending' | 'started' | 'starting' | 'startFailed' | 'noSession' | 'suspended';
export type CastState = 'connected' | 'connecting' | 'notConnected' | 'noDevicesAvailable';
export interface CastSession {
    messageReceived: Observable<any>;
    mediaSessionChanged: Observable<CastMedia>;
    getMediaSession(): CastMedia;
    loadMedia(request: CastMediaRequest): Promise<void>;
    sendMessage(message: any): Promise<void>;
    getCastDevice(): CastReceiver;
}

export interface CastReceiver {
    label: string;
    friendlyName: string;
}

export interface CastMedia {
    info: CastMediaInfo;
    pause(): Promise<void>;
    play(): Promise<void>;
    setVolume(level: number): Promise<void>;
    seek(position: number): Promise<void>;
}

export interface CastMediaInfo {
    contentId: string;
    contentType: string;
    contentUrl?: string;
    duration: number;
    metadata: any;
}

export interface CastMediaRequest {
    contentId: string;
    contentType: string;
    currentTime?: number;
}

export interface CastPlaybackState {
    duration: number;
    currentTime: number;
    playbackState: 'unstarted' | 'playing' | 'paused' | 'buffering';
}

export abstract class GoogleCastPlugin {
    abstract initialize();

    private _supported = false;
    get supported() { return this._supported; }
    protected set supported(value) { this._supported = value; }

    private _session: CastSession = undefined;
    get session() { return this._session; }
    private _sessionChanged = new BehaviorSubject<CastSession>(this._session);
    readonly sessionChanged = this._sessionChanged.asObservable();
    protected set session(value) {
        if (this._session === value)
            return;
        this._session = value;
        this._sessionChanged.next(value);
    }

    protected _finished = new Subject<void>();
    readonly finished = this._finished.asObservable();

    private _sessionState: CastSessionState = 'noSession';
    get sessionState() { return this._sessionState; }
    private _sessionStateChanged = new BehaviorSubject<CastSessionState>(this._sessionState);
    readonly sessionStateChanged = this._sessionStateChanged.asObservable();
    protected set sessionState(value) {
        this._sessionState = value;
        this._sessionStateChanged.next(value);
    }

    private _castState: CastState = 'noDevicesAvailable';
    private _castStateChanged = new BehaviorSubject<CastState>(this._castState);
    readonly castStateChanged = this._castStateChanged.asObservable();
    protected set castState(value) {
        this._castState = value;
        this._castStateChanged.next(value);
    }

    private _playbackState: CastPlaybackState = { currentTime: 0, duration: 0, playbackState: 'unstarted' };
    private _playbackStateChanged = new BehaviorSubject<CastPlaybackState>(this._playbackState);
    readonly playbackStateChanged = this._playbackStateChanged.asObservable();
    public get playbackState() {
        return this._playbackState;
    }
    protected set playbackState(value) {
        this._playbackState = value;
        this._playbackStateChanged.next(value);
    }

    abstract requestSession();
    abstract endSession(stopCasting: boolean);
}
