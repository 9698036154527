<p>YouTube chat is read-only in the TYT app. To participate, you'll need to
  watch this stream on TYT.com or within the YouTube app.</p>

<div class="actions">
  <a mat-raised-button [href]="watchOnWebUrl" target="_blank" class="skip-rewrite" (click)="watchOnWeb.next()">Watch on TYT.com</a>
  <button mat-raised-button (click)="watchExternally.next()">Watch on YouTube</button>
</div>

<button mat-button (click)="hidden = true">
  OK, I understand
</button>