@if (!connected) {
  <a role="button" aria-label="Start Google Cast" href="javascript:;" (click)="startCast()" title="Google Cast">
    <img src="/assets/cast/not_connected.png" style="height: 1.5em;" />
  </a>
}

@if (connected) {
  <a role="button" aria-label="Stop Google Cast" href="javascript:;" (click)="stopCast()" title="Stop Casting">
    <img src="/assets/cast/connected.png" style="height: 1.5em;" />
  </a>
}
