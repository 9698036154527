import { Injectable, inject } from '@angular/core';
import { LoggerService } from '@tytapp/common';
import { HostApi, Request, Response } from '@tytapp/common';
import { Observable, Subject } from 'rxjs';
import { CastMedia, CastMediaInfo, CastMediaRequest, CastPlaybackState, CastReceiver, CastSession, GoogleCastPlugin } from './google-cast-plugin';

interface NativeGoogleCastSessionStateChangedEvent {
    type: 'google_cast_session_state_changed';
    state: 'resumed' | 'ended' | 'ending' | 'started' | 'starting' | 'startFailed' | 'noSession';
    receiver: CastReceiver;
}

interface NativeGoogleCastStateChangedEvent {
    type: 'google_cast_state_changed';
    state: 'connected' | 'connecting' | 'notConnected' | 'noDevicesAvailable';
}

interface NativeGoogleCastPlaybackStateChangedEvent {
    type: 'google_cast_playback_state_changed';
    playbackState: CastPlaybackState;
    mediaInfo: CastMediaInfo;
}

interface NativeGoogleCastSessionRequest {
    type: 'google_cast_request_session';
}

interface NativeGoogleCastEndSessionRequest {
    type: 'google_cast_end_session';
    stopCasting: boolean;
}

interface NativeGoogleCastMessageEvent {
    type: 'google_cast_message_received';
    message: any;
}

interface NativeGoogleCastSendMessageRequest extends NativeGoogleCastMessageEvent, Request {
    type: 'google_cast_message_received';
}

interface NativeGoogleCastSetVolumeRequest extends Request {
    type: 'google_cast_set_volume';
    level: number;
}

interface NativeGoogleCastSeekRequest extends Request {
    type: 'google_cast_seek';
    position: number;
}

interface NativeGoogleCastLoadMediaRequest extends Request {
    type: 'google_cast_load_media';
    content_id: string;
    content_type: string;
    current_time?: number;
}

class NativeGoogleCastMedia implements CastMedia {
    constructor(private castSession: NativeGoogleCastSession) {
    }

    readonly hostApi = this.castSession.hostApi;

    info: CastMediaInfo;

    async pause(): Promise<void> {
        await this.hostApi.sendRequest({ type: 'google_cast_pause' });
    }
    async play(): Promise<void> {
        await this.hostApi.sendRequest({ type: 'google_cast_play' });
    }

    async setVolume(level: number): Promise<void> {
        await this.hostApi.sendRequest<NativeGoogleCastSetVolumeRequest, Response>({
            type: 'google_cast_set_volume', level
        });
    }

    async seek(position: number): Promise<void> {
        await this.hostApi.sendRequest<NativeGoogleCastSeekRequest, Response>({
            type: 'google_cast_seek', position: position * 1000 | 0
        });
    }
}

class NativeGoogleCastSession implements CastSession {
    constructor(private castPlugin: NativeGoogleCastPlugin) {
        this.nativeMessageReceived.subscribe(e => {
            this._messageReceived.next(e.message);
        });

    }

    readonly hostApi = this.castPlugin.hostApi;
    readonly media = new NativeGoogleCastMedia(this);

    castDevice: CastReceiver = undefined;

    get nativeMessageReceived() {
        return this.hostApi.messageOfType<NativeGoogleCastMessageEvent>('google_cast_message_received');
    }

    private _messageReceived = new Subject<any>();
    messageReceived = this._messageReceived.asObservable();

    mediaSessionChanged: Observable<any>;

    getMediaSession(): CastMedia {
        return this.media;
    }

    async loadMedia(request: CastMediaRequest): Promise<void> {
        await this.hostApi.sendRequest<NativeGoogleCastLoadMediaRequest, Response>({
            type: 'google_cast_load_media',
            content_id: request.contentId,
            content_type: request.contentType,
            current_time: request.currentTime
        });
    }

    async sendMessage(message: any): Promise<void> {
        await this.hostApi.sendRequest<NativeGoogleCastSendMessageRequest, Response>({
            type: 'google_cast_message_received',
            message
        });
    }

    getCastDevice(): CastReceiver {
        return this.castDevice;
    }
}

@Injectable()
export class NativeGoogleCastPlugin extends GoogleCastPlugin {
    readonly hostApi = inject(HostApi);
    private readonly logger = inject(LoggerService);

    readonly nativeCastSession = new NativeGoogleCastSession(this);

    constructor() {
        super();
        this.supported = true;
    }

    private nativeSessionStateChanged = this.hostApi.messageOfType<NativeGoogleCastSessionStateChangedEvent>('google_cast_session_state_changed');
    private nativeCastStateChanged = this.hostApi.messageOfType<NativeGoogleCastStateChangedEvent>('google_cast_state_changed');
    private nativePlaybackStateChanged = this.hostApi.messageOfType<NativeGoogleCastPlaybackStateChangedEvent>('google_cast_playback_state_changed');
    private nativePlaybackFinished = this.hostApi.messageOfType('google_cast_playback_finished');

    initialize() {
        this.nativeSessionStateChanged.subscribe(e => {
            this.logger.info(`[GoogleCast/Native] Session state changed: ${e.state}`);

            if (['noSession', 'ended', 'startFailed'].includes(e.state)) {
                this.nativeCastSession.media.info = undefined;
                this.session = undefined;
            } else {
                this.session = this.nativeCastSession;
            }

            this.nativeCastSession.castDevice = e.receiver;
            this.sessionState = e.state;
        });

        this.nativeCastStateChanged.subscribe(e => {
            this.logger.info(`[GoogleCast/Native] Cast state changed: ${e.state}`);

            if (e.state === 'noDevicesAvailable')
                this.castState = 'notConnected';
            else
                this.castState = e.state;
        });

        this.nativePlaybackStateChanged.subscribe(e => {
            e.playbackState.duration /= 1000;
            e.playbackState.currentTime /= 1000;
            this.playbackState = e.playbackState;

            if (e.mediaInfo) {
                e.mediaInfo.duration /= 1000;
                this.nativeCastSession.media.info = e.mediaInfo;
            } else {
                e.mediaInfo = null;
            }
        });

        this.nativePlaybackFinished.subscribe(() => this._finished.next());
    }

    requestSession() {
        this.hostApi.sendMessage<NativeGoogleCastSessionRequest>({ type: 'google_cast_request_session' });
    }

    endSession(stopCasting: boolean) {
        this.hostApi.sendMessage<NativeGoogleCastEndSessionRequest>({ type: 'google_cast_end_session', stopCasting });
    }
}
