import { Component, inject } from '@angular/core';
import { AirPlayService } from '@tytapp/media-playback/airplay.service';

@Component({
    selector: 'tyt-airplay-sender',
    template: ''
})
export class AirplaySenderComponent {
    airplay = inject(AirPlayService);

    ngOnInit() {
        this.airplay.initialize();
    }
}