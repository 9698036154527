import { ApiLiveStream, ApiPodcast, ApiVOD } from '@tytapp/api';

export const PLAYLIST_TYPES = ['episodes', 'clips', 'podcasts', 'audio-stories'] as const;
export type Playlist = (typeof PLAYLIST_TYPES)[number];

export function stringToPlaylist(playlist: Playlist): Playlist;
export function stringToPlaylist(playlist: string): Playlist | undefined {
    if (isPlaylist(playlist))
        return playlist;

    return undefined;
}

export function isPlaylist(playlist: string): playlist is Playlist {
    return PLAYLIST_TYPES.includes(<Playlist>playlist);
}

export function isLiveStream(media: ApiVOD | ApiPodcast | ApiLiveStream): media is ApiLiveStream {
    return media?.type === 'live_stream';
}

export function isVOD(media: ApiVOD | ApiPodcast | ApiLiveStream): media is ApiLiveStream {
    return media?.type === 'vod';
}

export function isPodcast(media: ApiVOD | ApiPodcast | ApiLiveStream): media is ApiLiveStream {
    return media?.type === 'podcast';
}

export const MEDIA_TYPE_HELPER = {
    isLiveStream, isVOD, isPodcast, isPlaylist, stringToPlaylist
}