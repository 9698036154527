import { environment } from '@tytapp/environment';
import { CastMedia, CastSession, CastSessionState, CastState, GoogleCastPlugin } from './google-cast-plugin';
import { lazyConnection } from '@banta/sdk';
import { Injectable, NgZone, inject } from '@angular/core';
import { isServerSide } from '@tytapp/environment-utils';
import { RemotePlayerChangedEvent } from '../../../local-typings/cast.framework';
import { LoggerService } from '@tytapp/common';

const CAST_NAMESPACE_TYT = 'urn:x-cast:com.tyt.cast';

@Injectable()
export class OfficialGoogleCastPlugin extends GoogleCastPlugin {
    private ngZone = inject(NgZone);
    private logger = inject(LoggerService).configure({ source: 'googlecast' });

    private initialized = false;

    async loadSdk() {
        await new Promise<void>(resolve => {
            window['__onGCastApiAvailable'] = (isAvailable) => {
                if (typeof chrome === 'undefined' || typeof chrome.cast === 'undefined') {
                    this.supported = false;
                    this.logger.warning(`[GoogleCast/Official] Google Cast reported being available, but chrome.cast is not defined. (This can happen while emulating iOS devices in Chrome Devtools)`);
                    return;
                }

                if (isAvailable) {
                    resolve();
                } else {
                    this.logger.info('[GoogleCast/Official] (I) Google Cast: Not available');
                }
            };

            let script = document.createElement('script');
            script.src = "https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1";
            document.body.appendChild(script);
        });
    }

    private remotePlayer: cast.framework.RemotePlayer;
    private remotePlayerController: cast.framework.RemotePlayerController;

    async initialize() {
        if (this.initialized || isServerSide())
            return;

        this.initialized = true;
        await this.loadSdk();
        this.supported = true;

        this.connectRemotePlayer();

        this.castContext.setOptions({
            autoJoinPolicy: chrome.cast?.AutoJoinPolicy?.TAB_AND_ORIGIN_SCOPED ?? <any>"tab_and_origin_scoped",
            receiverApplicationId: environment.googleCastAppId
        });

        this.castContext.addEventListener(cast.framework.CastContextEventType.SESSION_STATE_CHANGED, e => {
            this.logger.info(`Session state change: ${e.sessionState}`);

            const stateMap: Record<string, CastSessionState> = {
                [cast.framework.SessionState.SESSION_RESUMED]: 'resumed',
                [cast.framework.SessionState.SESSION_ENDED]: 'ended',
                [cast.framework.SessionState.SESSION_ENDING]: 'ending',
                [cast.framework.SessionState.SESSION_STARTED]: 'started',
                [cast.framework.SessionState.SESSION_STARTING]: 'starting',
                [cast.framework.SessionState.SESSION_START_FAILED]: 'startFailed',
                [cast.framework.SessionState.NO_SESSION]: 'noSession',
            };

            if (e.sessionState === cast.framework.SessionState.SESSION_ENDED) {
                this.session = null;
                this.disconnectRemotePlayer();
            } else {
                this.session = this.findOrCreateSession(e.session);
            }

            this.sessionState = stateMap[e.sessionState];
        });

        this.castContext.addEventListener(cast.framework.CastContextEventType.CAST_STATE_CHANGED, e => {
            this.logger.info(`[GoogleCast/Official] Cast state changed: ${e.castState}`);
            const stateMap: Record<string, CastState> = {
                [cast.framework.CastState.CONNECTED]: 'connected',
                [cast.framework.CastState.CONNECTING]: 'connecting',
                [cast.framework.CastState.NOT_CONNECTED]: 'notConnected',
                [cast.framework.CastState.NO_DEVICES_AVAILABLE]: 'noDevicesAvailable'
            };

            if (this.session && e.castState === cast.framework.CastState.NOT_CONNECTED) {
                console.warn(`[GoogleCast/Official] Received NOT_CONNECTED before session was removed from state`);
                this.session = null;
            }
            this.castState = stateMap[e.castState];
        });
    }

    private disconnectRemotePlayer() {
        if (this.remotePlayerController) {
            this.remotePlayerController.removeEventListener(
                cast.framework.RemotePlayerEventType.ANY_CHANGE,
                this.remotePlayerListener
            );
        }
    }

    private remotePlayerListener;
    private connectRemotePlayer() {
        if (typeof cast === 'undefined') {
            this.logger.info(`Cast SDK is not available. Skipping connectRemotePlayer()`);
            return;
        }

        this.remotePlayer = new cast.framework.RemotePlayer();
        this.remotePlayerController = new cast.framework.RemotePlayerController(this.remotePlayer);

        this.disconnectRemotePlayer();
        this.remotePlayerController.addEventListener(
            cast.framework.RemotePlayerEventType.ANY_CHANGE,
            this.remotePlayerListener = (ev: RemotePlayerChangedEvent) => {
                if (ev.field === 'isMediaLoaded' && ev.value === false) {
                    this._finished.next();
                }

                console.log(`state: `, ev);
                const STATE_MAP = {
                    IDLE: 'unstarted',
                    PLAYING: 'playing',
                    PAUSED: 'paused',
                    BUFFERING: 'buffering'
                } as const;

                try {
                    this.ngZone.runGuarded(() => {
                        this.playbackState = {
                            duration: this.remotePlayer.duration,
                            currentTime: this.remotePlayer.currentTime,
                            playbackState: STATE_MAP[this.remotePlayer.playerState]
                        }
                    });
                } catch (e) {
                    console.error(`Caught error during updateState():`);
                    console.error(e);
                }
            }
        );
    }
    private get castContext() {
        if (typeof cast === 'undefined')
            return undefined;

        return cast.framework.CastContext.getInstance();
    }

    private sessionMap = new WeakMap<cast.framework.CastSession, CastSession>();
    private mediaMap = new WeakMap<chrome.cast.media.Media, CastMedia>();

    private findOrCreateMedia(sdkMedia: chrome.cast.media.Media): CastMedia {
        if (!sdkMedia)
            return undefined;

        if (!this.mediaMap.has(sdkMedia)) {
            return {
                info: sdkMedia.media ? {
                    contentId: sdkMedia.media.contentId,
                    contentType: sdkMedia.media.contentType,
                    duration: sdkMedia.media.duration,
                    metadata: sdkMedia.media.metadata
                } : undefined,
                pause: async () => {
                    await new Promise<void>((resolve, reject) => {
                        sdkMedia.pause(
                            new chrome.cast.media.PauseRequest(),
                            () => resolve(),
                            err => reject(err)
                        );
                    });
                },
                play: async () => {
                    await new Promise<void>((resolve, reject) => {
                        sdkMedia.play(
                            new chrome.cast.media.PlayRequest(),
                            () => resolve(),
                            err => reject(err)
                        );
                    });
                },
                setVolume: async (level: number) => {
                    await new Promise<void>((resolve, reject) => {
                        sdkMedia.setVolume(
                            new chrome.cast.media.VolumeRequest(new chrome.cast.Volume(level, false)),
                            () => resolve(),
                            err => reject(err)
                        );
                    });
                },
                seek: async (position: number) => {
                    let seekRequest = new chrome.cast.media.SeekRequest();
                    seekRequest.currentTime = position;
                    seekRequest.resumeState = chrome.cast.media.ResumeState.PLAYBACK_START;

                    this.remotePlayer.currentTime = position;
                    this.remotePlayerController.seek();
                    // await new Promise<void>((resolve, reject) => {
                    //     sdkMedia.seek(
                    //         seekRequest,
                    //         () => resolve(),
                    //         err => reject(err)
                    //     );
                    // });
                }
            }
        }

        return this.mediaMap.get(sdkMedia);
    }

    private findOrCreateSession(sdkSession: cast.framework.CastSession) {
        if (!sdkSession)
            return undefined;

        if (!this.sessionMap.has(sdkSession)) {
            let messageListener: (namespace: string, message: string) => void;
            let mediaListener: (event: cast.framework.MediaSessionEventData) => void;

            this.sessionMap.set(sdkSession, {
                messageReceived: lazyConnection({
                    start: subject => {
                        sdkSession.addMessageListener(CAST_NAMESPACE_TYT, messageListener = async (ns, rawMessage) => {
                            this.logger.info(`[GoogleCast/Official] GoogleCast: Received event (${ns}):`);
                            subject.next(JSON.parse(rawMessage));
                        });
                    },
                    stop: () => sdkSession.removeMessageListener(CAST_NAMESPACE_TYT, messageListener),
                }),
                getMediaSession: () => this.findOrCreateMedia(sdkSession.getMediaSession()),
                mediaSessionChanged: lazyConnection({
                    start: subject => sdkSession.addEventListener(
                            cast.framework.SessionEventType.MEDIA_SESSION,
                            mediaListener = e => subject.next(this.findOrCreateMedia(e.mediaSession))
                    ),
                    stop: () => {
                        sdkSession.removeEventListener(
                            cast.framework.SessionEventType.MEDIA_SESSION,
                            mediaListener
                        )
                    }
                }),
                loadMedia: async request => {
                    let sdkMediaInfo = new chrome.cast.media.MediaInfo(request.contentId, request.contentType);
                    let sdkRequest = new chrome.cast.media.LoadRequest(sdkMediaInfo);
                    sdkRequest.currentTime = request.currentTime ?? 0;

                    this.logger.info(`[GoogleCast/Official] loadMedia(): Request:`);
                    this.logger.inspect(request);

                    let error = await sdkSession.loadMedia(sdkRequest);
                    if (error)
                        throw new Error(error);
                },
                sendMessage: async (message) => {
                    let error = await sdkSession.sendMessage(CAST_NAMESPACE_TYT, message);
                    if (error)
                        throw new Error(error);
                },
                getCastDevice: () => {
                    let sdkDevice = sdkSession.getCastDevice();
                    if (!sdkDevice)
                        return undefined;
                    return {
                        friendlyName: sdkDevice.friendlyName,
                        label: sdkDevice.label
                    }
                }
            });
        }

        return this.sessionMap.get(sdkSession);
    }

    requestSession() {
        this.castContext.requestSession();
    }

    endSession(stopCasting: boolean) {
        let castContext = cast.framework.CastContext.getInstance();
        // let castSession = castContext.getCurrentSession();
        // castSession.endSession(stopCasting);

        castContext.endCurrentSession(true);
    }

    async seek(position: number): Promise<void> {
        let castContext = cast.framework.CastContext.getInstance();
        let mediaSession = castContext.getCurrentSession()?.getMediaSession();

        if (!mediaSession) {
            console.warn(`[GoogleCast/Official] While trying to seek: No media session`);
            return;
        }

        return new Promise<void>((resolve, reject) => {
            mediaSession.seek({
                currentTime: position,
                resumeState: chrome.cast.media.ResumeState.PLAYBACK_START,
                customData: {}
            }, () => {
                resolve();
            }, (err) => {
                reject(err);
            });
        })
    }

}
