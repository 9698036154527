<div
  role="slider"
  id="seek-button-container"
  tabindex="0"
  aria-valuemin="0"
  [attr.aria-valuemax]="duration"
  [attr.aria-valuenow]="a11ySeekValue"
  [attr.aria-valuetext]="seekText"
  (keydown)="handleKey($event)"
  (focus)="setIsFocused(true)"
  (blur)="setIsFocused(false)"
  >
  <button id="seek-button" [style.left]="(dragging? seekDragPosition * 100 : displaySeekPosition * 100)+'%'"
    (focus)="setIsFocused(true)"
    (blur)="setIsFocused(false)"
    (keydown)="handleKey($event)"
    >
    <div id="seek-button-visual">
      @if (dragging) {
        <span id="time-span">{{ seekPreviewValue | duration: 'short-seconds'}}</span>
      }
    </div>
  </button>
</div>
<div #seekTrackReceiver id="seek-receiver"
  (pointerdown)="seekPointerDown($event)"
  (pointerup)="seekPointerUp($event)"
(pointermove)="seekPointerMove($event)"></div>
<div id="seek-bar"></div>