<button type="button" mat-icon-button [class.connected]="connected" aria-label="Airplay" #button id="airplay" (click)="click()" title="AirPlay">
  @if (isAudio) {
    <!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <svg version="1.1" id="text-OL" x="0px" y="0px" viewBox="0 0 125 125" style="enable-background:new 0 0 125 125;"
      xml:space="preserve">
      <rect class="st0" width="125" height="125" />
      <g id="_Group_">
        <g id="_Group_2">
          <path id="_Path_" class="st1" d="M43.5,84.1l1.3-1.5c0.3-0.3,0.3-0.8,0-1.1c-10.5-9.7-11.2-26.2-1.4-36.7s26.2-11.2,36.7-1.4
					s11.2,26.2,1.4,36.7c-0.5,0.5-0.9,1-1.4,1.4c-0.3,0.3-0.3,0.8,0,1.1l1.3,1.5c0.3,0.3,0.8,0.3,1.1,0.1c0,0,0,0,0,0
					c12-11.1,12.7-29.7,1.7-41.7c-11.1-12-29.7-12.7-41.7-1.7s-12.7,29.7-1.7,41.7c0.5,0.6,1.1,1.1,1.7,1.7
					C42.8,84.4,43.2,84.4,43.5,84.1z" />
            <path id="_Path_2" class="st1"
            d="M44.8,62.5c0-9.7,7.9-17.6,17.6-17.6S80,52.9,80,62.6c0,4.8-2,9.5-5.5,12.8
					c-0.3,0.3-0.3,0.8,0,1.1l1.3,1.5c0.3,0.3,0.8,0.4,1.1,0.1c0,0,0,0,0,0c8.5-8,8.9-21.3,1-29.8s-21.3-8.9-29.8-1S39.1,68.5,47,77
					c0.3,0.3,0.6,0.7,1,1c0.3,0.3,0.8,0.3,1.1,0c0,0,0,0,0,0l1.3-1.5c0.3-0.3,0.3-0.8,0-1.1C46.9,72.1,44.8,67.4,44.8,62.5z" />
              <path id="_Path_3" class="st1"
            d="M53.2,62.5c0-5.1,4.1-9.2,9.2-9.2c5.1,0,9.2,4.1,9.2,9.2c0,2.5-1,4.8-2.8,6.6
					c-0.3,0.3-0.3,0.8,0,1.1l1.3,1.5c0.3,0.3,0.8,0.3,1.1,0c0,0,0,0,0,0c5-4.9,5.2-12.9,0.3-18s-12.9-5.2-18-0.3s-5.2,12.9-0.3,18
					c0.1,0.1,0.2,0.2,0.3,0.3c0.3,0.3,0.8,0.3,1.1,0c0,0,0,0,0,0l1.3-1.5c0.3-0.3,0.3-0.8,0-1.1C54.2,67.4,53.2,65,53.2,62.5z" />
              </g>
        <path id="_Path_4" class="st1" d="M80.9,89.1L63.5,69.3c-0.5-0.6-1.3-0.6-1.9-0.1c0,0-0.1,0.1-0.1,0.1L43.9,89.1
				c-0.4,0.5-0.4,1.2,0.1,1.7c0.2,0.2,0.5,0.3,0.7,0.3h35.3c0.6,0,1.2-0.5,1.2-1.2C81.2,89.6,81.1,89.3,80.9,89.1z" />
              </g>
            </svg>
          }
          @if (!isAudio) {
            <mat-icon>airplay</mat-icon>
          }
        </button>