import { sleep } from '@tytapp/common';
import { PlaybackSession } from './playback-session';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MediaItem } from './media-item';
import { PlaybackState } from './playback-state';
import { MediaService } from './media-service';
import { srtToVTT } from '../srt-to-vtt';
import { environment } from '@tytapp/environments/environment.native-preproduction';

interface HTMLPictureInPictureWindow {
    width: number;
    height: number;
    addEventListener(type: 'resize', handler: (event: Event) => void);
    removeEventListener(type: 'resize', handler: (event: Event) => void);
}

export class HTMLMediaPlaybackSession implements PlaybackSession {
    constructor(
        private _item: MediaItem,
        private _service: MediaService,
        private _element: HTMLMediaElement,
        private _asset: any,
        private _startPosition: number = 0
    ) {
        if (_startPosition >= 0) {
            if (this._element.duration > 0) {
                this._element.currentTime = _startPosition;
            } else {
                let listener = () => {
                    this._element.currentTime = _startPosition;
                    this._element.removeEventListener('loadedmetadata', listener, false);
                };

                this._element.addEventListener('loadedmetadata', listener, false);
            }
        }

        let _currentPlayState: PlaybackState = 'unstarted';
        let updatePositions = () => {
            this._position = _element.currentTime;
            this._positionChanged.next(this._position);

            let previousDuration = this._length;
            let newDuration = this._element.duration;

            if (previousDuration != newDuration) {
                this._length = newDuration;
                this._lengthChanged.next(this._length);
            }
        };

        let updateState = () => {
            let _newPlayState: PlaybackState = 'unstarted';

            this._element;
            if (this._element.paused) {
                _newPlayState = 'paused';
            } else if (this._position > 0) {
                _newPlayState = 'playing';
            }

            if (_newPlayState != _currentPlayState) {
                _currentPlayState = _newPlayState;
                this._playState = _currentPlayState;
                this._playStateChanged.next(_currentPlayState);
            }

            // TODO: reconcile new vs old play state
        };

        this._element.addEventListener('playing', () => {
            if (_currentPlayState != 'playing') {
                _currentPlayState = 'playing';
                this._playState = _currentPlayState;
                this._playStateChanged.next(_currentPlayState);
            }
        });

        this._element.addEventListener('pause', () => {
            if (_currentPlayState != 'paused') {
                _currentPlayState = 'paused';
                this._playState = _currentPlayState;
                this._playStateChanged.next(_currentPlayState);
            }
        });

        this._element.addEventListener('timeupdate', () => updatePositions());

        this._element.addEventListener('loadedmetadata', async () => {
            updatePositions();
            await this.loadClosedCaptions();
        });

        this._element.addEventListener('waiting', () => {
            if (_currentPlayState != 'buffering') {
                _currentPlayState = 'buffering';
                this._playState = _currentPlayState;
                this._playStateChanged.next(_currentPlayState);
            }
        });

        this._element.addEventListener('ended', () => {
            this._finished.next();
        });

        this._element.addEventListener('error', ev => {
            console.error("Received error while playing back HTML5 media:");
            console.dir(ev);
        });

        this._element.addEventListener('leavepictureinpicture', ev => {
            this.pictureInPicture = false;
        });

        this._element.addEventListener('enterpictureinpicture', ev => {
            this.pictureInPicture = true;
        });
    }

    pictureInPicture: boolean;

    get supportsPictureInPicture() {
        // On native, the native Web Platform PiP API is not hooked up.
        // In the future we may make this happen, but for now this is handled
        // via Host API calls. See PlayerComponent and the 'media:picture_in_picture'
        // capability (and corresponding 'pip'/'allow_pip' Host API calls)

        return 'requestPictureInPicture' in this.element.nativeElement;
    }

    supportedPlaybackSpeeds = [0.25, 0.5, 1, 1.25, 1.5, 1.75, 2];

    _speedChanged: Subject<number> = new BehaviorSubject<number>(1);

    async enterPictureInPicture() {
        if ('requestPictureInPicture' in this.element.nativeElement) {
            try {
                let window: HTMLPictureInPictureWindow = (this.element.nativeElement as any).requestPictureInPicture();
            } catch (e) {
                console.error(`Failed to enter picture-in-picture:`);
                console.error(e);
            }
        }
    }

    get speedChanged() {
        return this._speedChanged;
    }

    _speed: number = 1;

    get speed() {
        return this._speed;
    }

    setSpeed(value: number) {
        this.element.nativeElement.playbackRate = value;
        this._speed = value;
        this._speedChanged.next(this._speed);
    }

    get item() {
        return this._item;
    }

    get playStateChanged(): Observable<PlaybackState> {
        return this._playStateChanged;
    }

    get volume(): number {
        return this.element.nativeElement.volume;
    }

    get mediaElement() {
        return this.element.nativeElement;
    }

    get playState() {
        return this._playState;
    }

    _playState: PlaybackState;

    private _volumeChanged: Subject<number> = new Subject<number>();
    get volumeChanged(): Observable<number> {
        return this._volumeChanged;
    }

    private _playStateChanged: Subject<PlaybackState> = new Subject<PlaybackState>();
    private _interval = null;
    private _position: number = null;
    private _length: number = null;
    private _positionChanged: BehaviorSubject<number> = new BehaviorSubject(null);
    private _lengthChanged: BehaviorSubject<number> = new BehaviorSubject(null);
    private _finished: Subject<void> = new Subject<void>();
    private _onSeek: Subject<number> = new Subject<number>();
    private _closedCaptionsEnabled: Subject<boolean> = new Subject<boolean>();

    get onSeek(): Observable<number> {
        return this._onSeek;
    }

    get service(): MediaService {
        return this._service;
    }

    get finished(): Observable<void> {
        return this._finished;
    }

    get length() {
        return this._length;
    }

    get lengthChanged(): Observable<number> {
        return this._lengthChanged;
    }

    get positionChanged(): Observable<number> {
        return this._positionChanged;
    }

    get position() {
        return this._position;
    }

    get element() {
        return { nativeElement: this._element };
    }

    get closedCaptionsEnabled(): Observable<boolean> {
        return this._closedCaptionsEnabled;
    }

    readonly seekable = true;

    seek(positionInSeconds: number) {
        this._element.currentTime = positionInSeconds;
        this._onSeek.next(positionInSeconds);
    }

    protected removeElementOnDestroy: boolean = true;

    async destroy() {
        clearInterval(this._interval);
        this._interval = null;

        this._element.src = '';

        if (this.removeElementOnDestroy)
            this._element.remove();

        if (this.pictureInPicture)
            await document.exitPictureInPicture();
        await sleep(1);
    }

    async pause() {
        await this._element.pause();
    }

    get asset() {
        return this._asset;
    }

    readonly hasChat = false;
    readonly chatEmbed = null;

    async resume() {
        await this._element.play();
    }

    setVolume(volumeFactor: number) {
        this._element.volume = volumeFactor;
        this._volumeChanged.next(volumeFactor);
    }

    async loadClosedCaptions() {
        const track = document.createElement('track');
        track.kind = 'captions';
        track.label = 'video captions';
        this._element.crossOrigin = 'anonymous';

        //////////////////////////
        // Testing flags
        //
        if (typeof localStorage !== 'undefined' && localStorage['tyt:test_srt_subtitles'] === '1') {
            this._asset.vtt_url = undefined;
            this._asset.srt_url = 'https://content.jwplatform.com/tracks/yP8ftywF.srt';
        } else if (typeof localStorage !== 'undefined' && localStorage['tyt:test:test_vtt_subtitles'] === '1') {
            this._asset.vtt_url = 'https://cdn.jwplayer.com/tracks/IPHwZY3K';
            this._asset.srt_url = undefined;
        }
        //
        ///////////////////////////

        if (this._asset.vtt_url) {
            track.src = this._asset.vtt_url;
        } else if (this._asset.srt_url) {
            let response = await fetch(this._asset.srt_url);
            let srt = await response.text();
            track.src = URL.createObjectURL(new Blob([ srtToVTT(srt) ], { type: 'text/vtt' }));
        }

        this._element.appendChild(track);
        this._closedCaptionsEnabled.next(false);
    }

    showClosedCaptions(show: boolean) {
        this._element.textTracks[0].mode = show ? 'showing' : 'hidden';
        this._closedCaptionsEnabled.next(show);
    }

    async moveInto(rootElement: HTMLElement): Promise<void> {
        rootElement.append(this.element.nativeElement);
    }
}
